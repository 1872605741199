.tagsSelect {
  &.inline {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      display: none;
      background: transparent;
    }
  }
  .inlineContainer {
    width: 100%;
    display: flex;
    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 10px;
      position: relative;
      flex-shrink: 0;
    }
  }
  .tag {
    display: inline-flex;
    align-items: center;
    padding: 3px 12px;
    border: 1px solid #0097d9;
    border-radius: 30px;
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .3s;
    background: #EEEEEE;
    border: 1px solid #CCCCCC;
    flex-shrink: 0;
    &.selected {
      background-color: #F26E26;
      color: #fff;
    }
    &:first-child {
      margin-left: 30px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
