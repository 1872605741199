@import '../../mixins.scss';
@import '../../_variables.scss';

.imageInput {
  display: flex;
  align-items: center;
  justify-content: center;
  .imagePreview {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
    border: 2px dashed #cccccc;
    border-radius: 3px;
    background-color: $mainBackgroundColor;
    &.round {
      border-radius: 50%;
    }
    .uploadContent {
      @include absolute-cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      .uploadIcon {
        width: 40px;
        height: 34px;
        background-image: url(../../../../public/images/icons/photo.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .uploadPlacehodler {
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        color: $primaryColor;
      }
      .uploadRecommendations {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #999999;
      }
    }
  }
  input[type='file'] {
    display: none;
  }
}
