@import '../variables.scss';

.staticView {
  background: $white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 30px 25px;

  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    color: $secondaryTextColor;

    & + p {
      margin-top: 30px;
    }
  }

  ol {
    padding-inline-start: 20px;
    margin: 0 0 30px;
    li {
      font-weight: 700;
      padding-top: 30px;
      p {
        margin-top: 20px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        font-weight: normal;
        padding-top: 20px;
        p {
          margin-top: 0;
          &:first-child {
            margin-bottom: 20px;
            font-weight: bold;
          }
        }
        li {
          padding-top: 0;
        }
      }
    }
  }

  p, li {
    font-family: 'Open Sans';
    font-size: 0.875rem;
    line-height: 171%;
    color: $secondaryTextColor;
  }

  p {
    font-weight: normal;
  }

  .romanList {
    list-style-type: none;
    counter-reset: item;
    position: relative;
    li {
      padding-left: 22px;
      &:before {
        width: 10px;
        content: '('counter(item, lower-roman)')';
        counter-increment: item;
        position: absolute;
        margin-right: calc(100% - 5px);
        right: 10px;
      }
    }
  }
}
