@import '../../_variables.scss';

.feedHeader {
  display: flex;
  align-items: center;
  margin-bottom: $spaceMain;
  color: $secondaryTextColor;
  font-size: $fontSizeMain;

  @media all and (max-width: $break-point-xs) {
    flex-wrap: wrap;
  }

  .feedHeaderInfo {
    width: 100%;
    padding-right: $spaceHorizontal;
    @media all and (max-width: $break-point-xs) {
      padding-right: 0;
    }
  }
  
  .feedHeaderTitle {
    font-family: 'Tiempo', serif;
    font-weight: $fontWeightBold;
    font-size: $fontSizeTitle;
    line-height: 1.3;
    margin-bottom: 5px;
    @media all and (max-width: $break-point-xs) {
      margin-bottom: 15px;
    }

    .feedHeaderIcon {
      margin-right: $spaceMedium;
      position: relative;
      top: 3px;
    }
  }
  
  .feedHeaderFollow {
    width: 280px;
    padding: 8px 30px;
    transition: all 0.2s linear;

    &:hover {
      background-color: $primaryColor;
      color: white;
    }

    @media all and (max-width: $break-point-xs) {
      margin-top: 20px;
    }

    .feedHeaderFollows {
      margin-right: $spaceSmall;
      vertical-align: middle;
    }
  }
}