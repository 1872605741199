@import '../../_variables.scss';

.autocompleteInput {
  padding: 0;
  position: relative;
  &.simplified {
    input,
    input[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  input {
    appearance: none;
    border: solid 1px $outlineColor;
    background-color: #FFF;
    border-radius: 60px;
    width: 100%;
    color: #333333;
    padding: 14px 30px;
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
    position: relative;
    z-index: 9;
    &::placeholder {
      color: #CCCCCC;
    }
    &[type=number]::-webkit-inner-spin-button, 
    &[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      margin: 0;
    }
    &:disabled {
      background-color: #f5f5f5;
    }
    &.active {
      border-bottom: 1px solid #eee;
      border-radius: 20px 20px 0 0;
    }
  }
  .autocompleteOptions {
    border: 1px solid $outlineColor;
    border-top: none;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    position: absolute;
    z-index: 8;
    background-color: #FFF;
    width: 100%;
    max-height: 0;
    transition: all 0.15s linear;
    top: 24px;
    padding-top: 24px;
    opacity: 0;

    .autocompleteOption {
      display: flex;
      align-items: center;
      padding: 8px 15px;
      transition: all 0.1s linear;
      height: 56px;

      &.noResults {
        padding: 8px 25px;
        color: $secondaryTextColor;
      }

      &:not(.noResults):hover {
        background-color: $mainBackgroundColor;
        cursor: pointer;
      }

      .autocompleteImage {
        width: 35px;
        height: 35px;
        margin-right: 7px;
        overflow: hidden;
      }
    }
  }
  &.focused {
    .autocompleteOptions {
      max-height: 1000px;
      opacity: 1;
    }
  }
  .inputError {
    text-align: left;
    padding-left: 12px;
    font-size: 12px;
    color: $errorColor;
  }
}
