@import '../../variables.scss';

.checkboxInput {
  padding: 5px 0px;
  &.disabled {
    opacity: .6;
    .checkbox, .label {
      cursor: not-allowed;
    }
  }
  .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .checkbox {
    vertical-align: top;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: inline-block;
    border-radius: 2px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    text-align: center;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: pointer;
    &::before {
      font-family: 'Material Icons';
      content: 'check';
      font-size: 0px;
      color: #ffffff;
      line-height: 30px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 65ms;
    }
    &.checked {
      border-color: $primaryColor;
      background-color: $primaryColor;
      &::before {
        font-size: 24px;
      }
    }
  }
  .label {
    display: inline-block;
    padding-left: 10px;
    padding-top: 5px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
  }
  &.small {
    .checkbox {
      width: 18px;
      height: 18px;
      &::before {
        line-height: 16px;
      }
      &.checked {
        &::before {
          font-size: 14px;
        }
      }
    }
    .label {
      padding-top: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .inputError {
    margin-top: 2px;
    margin-bottom: 12px;
    text-align: left;
    padding-left: 42px;
    font-size: 12px;
    color: $errorColor;
  }
}
