@import '../../variables.scss';

.profilePlaceholderItem {
  margin-top: 27px;
  width: 100%;
}

.commentPlaceholderItem {
  width: 100%;
  background: $white;
}

.postPlaceholderItem {
  width: 100%;
  background: $white;
  margin-bottom: 20px;
}
