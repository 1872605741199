// Media Settings
$break-point-xxl: 1680px;
$break-point-xl: 1400px;
$break-point-lg: 1200px;
$break-point-md: 1024px;
$break-point-sm: 996px;
$break-point-xs: 640px;

/* Colors */
$primaryColor: #F26E26;
$secondaryColor: #3c3634;
$mainTextColor: #333333;
$secondaryTextColor: #666666;
$thirdTextColor: #999999;
$color1: #595959;
$color2: #8C8C8C;
$color3: #83cfcb;
$color4: #E6F7FF;
$color5: #0097d9;
$color6: #007DBA;
$color7: #DE1269;
$outlineColor: #CCCCCC;
$highlightColor: #ffeeea;
$successColor: #74ae3b;
$errorColor: #e41407;
$mainBackgroundColor: #f5f5f5;
$lightBackgroundColor: #eeeeee;
$white: #ffffff;
$black: #000000;
$dividerDark: #dddddd;

$colorMainStep: 4%;

/* Shadows */
$shadowContainer: 0px 10px 30px rgba($black, 0.05);

/* Rounds */
$radiusMain: 3px;
$radiusBig: 20px;
$radiusComplete: 50%;

/* Typography */
$fontSizeTitle: 30px;
$fontSizeHeader: 18px;
$fontSizeMain: 14px;
$fontSizeCaption: 12px;

$fontWeightRegular: 400;
$fontWeightSemibold: 600;
$fontWeightBold: 700;

/* Transitions */
$transitionMain: linear 160ms;

/* Spacings */
$spaceSmallest: 4px;
$spaceSmall: 8px;
$spaceMedium: 14px;
$spaceMain: 20px;
$spaceHorizontal: 30px;