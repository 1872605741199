@import '../../_variables.scss';

.messangerTextarea {
  transition: border-color $transitionMain;
  width: 100%;
  min-height: 20px;
  max-height: 300px;
  height: 50px;
  padding: $spaceMedium $spaceMain;
  resize: none;
  background-color: $white;
  border: 1px solid $outlineColor;
  border-radius: 25px;
  color: $secondaryTextColor;
  font-family: 'Open Sans', sans-serif;
  scrollbar-width: none;
  display: block;
  
  &::-webkit-scrollbar {
    display: none;
    background: transparent;
  }

  &::placeholder {
    color: $thirdTextColor;
  }

  &:hover {
    border-color: darken($outlineColor, $colorMainStep);
  }

  &:focus, &:active {
    border-color: $primaryColor;
  }
}