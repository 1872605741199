@import '../../_variables.scss';

.sidebarSection {
  padding-bottom: $spaceHorizontal;
  margin-bottom: 24px;
  position: relative;  
  border-bottom: 1px solid $dividerDark;

  &.withoutDivider {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }

  .sidebarEcoBusiness {
    position: absolute;
    top: -3px;
    right: 0;
  }

  .sidebarSectionTitle {
    margin-bottom: $spaceMain;
    font-size: $fontSizeHeader;
    font-weight: $fontWeightBold;
    line-height: 1;
  }
}