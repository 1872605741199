@import '../../_variables.scss';

.sidebar {
  width: 300px;
  margin-left: $spaceHorizontal;
  font-size: $fontSizeMain;

  @media all and (max-width: $break-point-xs) {
    margin-left: 0;
    margin-top: $spaceHorizontal;
    width: 100%;
  }

  .banner {
    min-width: 300px;
    min-height: 250px;
    text-align: center;
  }
}

.latestNewsItem {
  display: flex;
  margin-bottom: $spaceMain;
  color: $secondaryTextColor;
  text-decoration: none;
  transition: all 0.2s linear;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .latestNewsItemCover {
    display: block;
    width: 124px;
    height: 90px;
    margin-right: $spaceMain;
    object-fit: cover;
  }

  .latestNewsItemTheme {
    margin-bottom: $spaceSmallest;
    color: $thirdTextColor;
    font-size: $fontSizeCaption;
    text-transform: uppercase;
    line-height: 1;
  }
}

.agendaItem {
  display: flex;
  margin-bottom: $spaceMain;
  color: $secondaryTextColor;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .agendaItemCalendarDay {
    margin-right: $spaceMain;
  }

  .agendaItemCountry {
    margin-bottom: $spaceSmallest;
    color: $thirdTextColor;
    font-size: $fontSizeCaption;
    text-transform: uppercase;
    line-height: 1;
  }
}

.calendarDay {
  min-width: 50px;
  width: 50px;
  background-color: $white;
  border: 1px solid $outlineColor;
  border-radius: $radiusMain;
  margin-right: 16px;

  .calendarDayMonth {
    background-color: $outlineColor;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    color: $white;
  }

  .calendarDayDate {
    padding: 15px 0;
    font-size: $fontSizeHeader;
    font-weight: $fontWeightBold;
    text-align: center;
    line-height: 1;
    color: $secondaryTextColor;
    margin: 0 auto;
  }
}