@import '../../_variables.scss';

.postFormPopup {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px,
        rgba(0, 0, 0, 0.06) 0px 2px 12px, rgba(0, 0, 0, 0.04) 0px 8px 14px,
        rgba(0, 0, 0, 0.02) 0px 12px 16px;
      border-radius: 3px;
      transition: all 300ms ease 0s;
      position: relative;
      overflow: hidden;
      .popupContent {
        position: relative;
        overflow: auto;
        width: calc(100vw - 36px);
        max-height: calc(100vh - 200px);
        max-width: 570px;
        padding: 0;
        @media all and (max-width: 768px) {
          max-height: calc(100vh - 120px);
        }
      }
      .contentContainer {
        padding: 20px 0px 0px;
      }
    }
  }
  .postFormPopupFeed {
    padding: 0 0 20px 0;
    margin-bottom: 0;
    .formTitle {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eeeeee;
      font-size: 18px;
      line-height: 167%;
      text-align: center;
      color: #666666;
    }
    .authorContainer {
      padding: 18px 24px 0 24px;
      max-height: 200px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      .commentAvatar {
        margin-right: 20px;
      }
      .postContent {
        width: calc(100% - 80px);
        overflow-y: auto;
        height: 157px;
        .textInput {
          width: 100%;
          textarea {
            overflow: hidden;
            border: none;
            padding-bottom: 0;
          }
        }
        .imagePreview {
          width: 100%;
          height: 250px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          border: 1px solid $lightBackgroundColor;
          border-bottom: none;
          border-radius: $radiusMain $radiusMain 0 0;
        }
        .postPreviewContent {
          position: relative;
          border: 1px solid $lightBackgroundColor;
          border-top: none;
          border-radius: 0 0 $radiusMain $radiusMain;
          padding: $spaceMedium $spaceMain;
          margin-bottom: 30px;

          .postPreviewTitle {
            margin-bottom: $spaceSmall;
            font-size: $fontSizeHeader;
            font-weight: $fontWeightBold;
          }

          .postPreviewDescription {
            margin-bottom: $spaceSmall;
            color: $thirdTextColor;
          }

          .postPreviewUrl {
            transition: color $transitionMain;
            color: $thirdTextColor;
            font-weight: $fontWeightBold;
            cursor: pointer;
          }
          .closeButton {
            margin-left: 10px;
            cursor: pointer;
            &::before {
              content: '';
              display: block;
              position: absolute;
              right: 18px;
              top: 21px;
              width: 14px;
              height: 13px;
              background-color: #999999;
              mask: url(../../../../public/images/icons/cross.svg) no-repeat
                center;
              mask-size: contain;
            }
          }
        }
      }
    }
    .dataBlock {
      padding: 12px 24px 12px 24px;
      border-top: 1px solid $lightBackgroundColor;
      .closeButton {
        margin: auto 0;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(../../../../public/images/icons/cross.svg) no-repeat center;
          mask-size: contain;
        }
      }
      .uploadContainer {
        display: inline-block;
        display: flex;
        justify-content: baseline;
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          opacity: 0.7;
        }
      }
      .selectInput {
        width: 60% !important;
      }
      input[type='file'] {
        display: none;
      }
      .fileInfo {
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .topics {
      border-top: 1px solid #eeeeee;
      .subtitle {
        font-size: 14px;
        line-height: 214%;
        color: #999999;
        margin: 10px 30px;
      }
    }
    .submitPostBtn {
      min-height: 53px;
      width: calc(100% - 60px);
      background: #f26e26;
      border-radius: 3px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}
