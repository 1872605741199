@import '../../variables';

.postPreview {
  transition: background-color $transitionMain, border-color $transitionMain;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: $white;
  border-radius: $radiusMain;
  border: 1px solid $lightBackgroundColor;
  color: $secondaryTextColor;
  text-decoration: none;
  overflow: hidden;

  &:hover {
    background-color: lighten($mainBackgroundColor, $colorMainStep/2);

    .postPreviewContent {
      .postPreviewUrl {
        color: $secondaryTextColor;
      }
    }
  }

  &:active {
    background-color: $mainBackgroundColor;
    border-color: darken($lightBackgroundColor, $colorMainStep);

    .postPreviewContent {
      .postPreviewUrl {
        color: $secondaryTextColor;
      }
    }
  }

  .postPreviewCover {
    width: 100%;
    height: 350px;
    border-radius: $radiusMain $radiusMain 0 0;
    object-fit: cover;
    border-bottom: 1px solid $lightBackgroundColor;
  }

  .postPreviewContent {
    padding: $spaceMedium $spaceMain;
    border-radius: 0 0 $radiusMain $radiusMain;

    .postPreviewTitle {
      margin-bottom: $spaceSmall;
      font-size: $fontSizeHeader;
      font-weight: $fontWeightBold;
    }

    .postPreviewDescription {
      margin-bottom: $spaceSmall;
      color: $thirdTextColor;
    }

    .postPreviewUrl {
      transition: color $transitionMain;
      color: $thirdTextColor;
      font-weight: $fontWeightBold;
    }
  }

  &.hidden {
    display: none;
  }
}
