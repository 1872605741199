@import './base.scss';

.sdg-goal-list {
  display: flex;
  flex-wrap: wrap;

  .goal {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 220px;
    width: 220px;
    margin-bottom: 30px;

    &:not(:nth-child(4n)) {
      margin-right: 28px;
    }

    .goal-bg-color {
      position: relative;
      height: 102px;
      width: 100%;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      .goal-id {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        font-weight: 700;
        color: #666666;
        padding: 5px 10px;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 3px;
      }
    }

    .goal-name {
      display: flex;
      justify-content: center;
      flex: 1;
      width: 100%;
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #666666;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      margin-top: 60px;
    }

    .goal-image-container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      border-radius: 50%;
      margin-top: -50px;
      margin-left: -50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
