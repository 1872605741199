@import '../../_variables.scss';

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: $radiusComplete;
  overflow: hidden;

  &.tiny {
    width: 30px;
    height: 30px;
  }

  &.small {
    width: 50px;
    height: 50px;
  }

  &.big {
    width: 160px;
    height: 160px;
  }

  &.noAvatar {
    background-color: $outlineColor;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      display: block;
      transform: translateX(-50%);
      width: 80px;
      height: 80px;
      background-color: #fff;
      mask: url(../../../../public/images/icons/profile.svg) no-repeat center /
        contain;
    }
    &.tiny:before {
      width: 28px;
      height: 28px;
    }
    &.small:before {
      width: 40px;
      height: 40px;
    }
    &.big:before {
      width: 120px;
      height: 120px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
