@import '../mixins.scss';
@import '../variables.scss';

.editProfileView {
  width: 100%;
  &.wizard {
  }
  .profileContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 900px;
    .profileForm {
      width: 100%;
    }
    .profileImage {
      flex-shrink: 0;
      width: 200px;
      margin-left: 55px;
      height: 100%;
      border-radius: 0;
    }
  }
  .pairedInputBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .inputBlock {
      width: calc(50% - 10px);
    }
  }
  .inputBlock {
    width: 100%;
    position: relative;
    .characterCounter {
      position: absolute;
      top: 0px;
      right: 0px;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-align: right;
      color: #999999;
    }
    label {
      display: block;
      margin: 24px 0px 6px;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #666666;
    }
  }
  .buttonBlock {
    border-top: 1px solid #EEEEEE;
    padding-top: 32px;
    margin-top: 32px;
    button {
      width: 210px;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      color: #FFFFFF;
      text-transform: none;
    }
  }
}

@media all and (max-width: 768px) {
  .editProfileView {
    .profileContent {
      flex-direction: column-reverse;
      .profileImage {
        width: 200px;
        margin: 0 auto;
        .inputBlock {
          text-align: center;
        }
      }
    }
    .buttonBlock {
      button {
        width: 100%;
      }
    }
  }
}
