@import '../../_variables.scss';

.profileCard {
  position: relative;
  border: 1px solid $lightBackgroundColor;
  border-radius: 3px;
  width: 22.2%;
  margin: 14px;
  text-align: center;
  background: linear-gradient(
    180deg,
    $mainBackgroundColor 0%,
    $mainBackgroundColor 35%,
    #fff 35%,
    #fff 100%
  );
  padding: 0 16px 18px;
  &:nth-child(4n) {
    margin-right: 0;
  }

  @media all and (max-width: $break-point-xs) {
    width: 100%;
    flex-shrink: 0;
    margin: 14px 0;
  }

  .remove {
    position: absolute;
    width: 11px;
    height: 11px;
    mask: url(../../../../public/images/icons/cross.svg) no-repeat center /
      contain;
    background-color: $primaryColor;
    top: 15px;
    right: 15px;
  }

  .profileType {
    font-weight: bold;
    font-size: 11px;
    line-height: 30px;
    color: $color6;
    text-transform: uppercase;
    margin: 10px auto;

    &.organization {
      color: $color7;
    }
  }

  .profileImage {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    img {
      height: 100%;
      display: block;
      width: 100%;
      border-radius: 50%;
    }
    &.noAvatar {
      background-color: $outlineColor;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        width: 80px;
        height: 80px;
        background-color: #fff;
        mask: url(../../../../public/images/icons/profile.svg) no-repeat center /
          contain;
      }
    }
  }

  .profileName {
    font-weight: bold;
    font-size: 14px;
    color: $secondaryTextColor;
    margin: 15px 0 12px;
    .subscribed {
      margin-left: 5px;
      height: 17px;
      width: 35px;
      //background-image: url(../../../../public/images/home/pro.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: inline-block;
      position: relative;
      top: 3px;
    }
  }

  .profileHeadline {
    font-size: 12px;
    line-height: 18px;
    color: $thirdTextColor;
    height: 53px;
  }

  .profileLocation {
    font-size: 12px;
    line-height: 20px;
    color: $thirdTextColor;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 9px;
    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 12px;
      mask: url(../../../../public/images/icons/location.svg) no-repeat center;
      mask-size: contain;
      background-color: $thirdTextColor;
      margin-right: 5px;
    }
  }
}
