@import '../../variables.scss';
@import '../../mixins.scss';

.selectButton {
  position: relative;
  cursor: pointer;
  color: $secondaryTextColor;
  background-color: $lightBackgroundColor;
  border: 1px solid $outlineColor;
  transition: all 0.2s linear;
  font-size: 14px;
  line-height: 257%;
  text-align: center;
  border-radius: 60px;
  padding: 1px 16px 2px;

  &.small {
    font-size: 12px;
    line-height: 195%;
    padding: 0 10px;
  }

  &:hover,
  &.selected {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #FFF;
  }
}