@import '../_variables.scss';

.switchContent {
  width: 100%;
  @media all and (max-width: $break-point-xs) {
    width: inherit;
  }
  .item {
    cursor: pointer;
    height: 100px;
    border-top: 1px solid $lightBackgroundColor;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: $secondaryTextColor;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .selected {
    background-image: url(../../../public/images/home/selected.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    margin-left: auto;
  }

  .subscribed {
    margin-left: 10px;
    height: 17px;
    width: 35px;
    //background-image: url(../../../public/images/home/pro.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
