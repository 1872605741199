@import '../mixins.scss';
@import '../variables.scss';

.editSolutionsView {
  .buttonBlock {
    border-top: 1px solid #EEEEEE;
    padding-top: 32px;
    margin-top: 16px;
    button {
      width: 210px;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      color: #FFFFFF;
      text-transform: none;
    }
  }
  .solutionsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .solutionItem {
      background: $mainBackgroundColor;
      border-radius: 3px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .solutionContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .solutionColumn {
          display: flex;
          flex-direction: column;
          width: calc(50% - 8px);
          position: relative;
        }
      }
      .solutionControls {
        display: flex;
        flex-direction: row;
        padding: 10px 0px 5px;
        button {
          font-size: 14px;
          line-height: 36px;
          width: 220px;
          &.delete {
            width: 214px;
            border: 1px solid #999999;
            color: #999999;
            background-color: transparent;
            margin-left: 16px;
          }
        }
      }
    }
    .itemTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      color: #666666;
      margin-bottom: 12px;
    }
    .characterCounter {
      position: absolute;
      top: 0px;
      right: 0px;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-align: right;
      color: #999999;
    }
    .selectInput {
      margin-bottom: 16px;
    }
    .textInput {
      margin-bottom: 16px;
      input,
      textarea {
        color: #666666;
      }
      textarea {
        height: 218px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .editSolutionsView {
    .solutionsContainer {
      .solutionItem {
        .solutionContent {
          flex-direction: column;
          .solutionColumn {
            width: 100%;
          }
        }
        .solutionControls {
          flex-direction: column;
          button {
            width: 100%;
            &.delete {
              margin-left: 0px;
              margin-top: 16px;
            }
          }
        }
      }
      .itemTitle {
        padding-right: 60px;
      }
    }
    .buttonBlock {
      button {
        width: 100%;
      }
    }
  }
}
