@import '../../mixins.scss';
@import '../../_variables.scss';

.searchInput {
  width: 100%;
  background: #ffffff;
  border-radius: 60px;
  padding: 8px 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    content: '';
    width: 17px;
    height: 17px;
    margin-right: 13px;
    background-image: url(../../../../public/images/icons/search.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.shadowed {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }
  &.bordered {
    border: 1px solid #ccc;
  }
  &.transparent {
    background-color: rgba(255, 255, 255, 0.2);
    input {
      color: #aaaaaa;
    }
  }
  input {
    appearance: none;
    border: none;
    background-color: transparent;
    border-radius: 0px;
    width: 100%;
    color: #333333;
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
    &::placeholder {
      color: #aaaaaa;
    }
  }
}
