@import '../mixins.scss';
@import '../variables.scss';

.registerView {
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url(../../../public/images/register/register_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .registerLogo {
    height: 46px;
    width: 171px;
    margin: 30px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #666666;
    text-align: center;
    background-image: url(../../../public/images/logo_white.png);
    background-size: 171px 46px;
    background-repeat: no-repeat;
    background-position: 0 center;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url(../../../public/images/logo_white@2x.png);
    }
  }
  @media all and (min-width: 768px) {
    .registerContainer {
      display: flex;
      height: calc(100vh - 120px);
      align-items: center;
    }
  }
  .registerContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 720px;
    padding: 0px 20px;
    align-items: center;
    margin-bottom: 60px;

    .registerTitle {
      margin-bottom: 16px;
      font-family: Tiempo;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #ffffff;
    }
    .registerSubtitle {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;
      text-align: center;
      color: #ffffff;
    }
    .registerSelect {
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      .registerType {
        width: 250px;
        height: 320px;
        margin: 15px;
        padding: 14px 15px;
        background: #ffffff;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .typeIcon {
          margin-top: auto;
          margin-bottom: 38px;
          width: 54px;
          height: 50px;
          background-position: center;
          background-repeat: no-repeat;
          &.individual {
            background-image: url(../../../public/images/register/individual.svg);
          }
          &.organisation {
            background-image: url(../../../public/images/register/organisation.svg);
          }
        }
        span {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #666666;
        }
        label {
          font-family: Tiempo;
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          text-align: center;
          color: #666666;
        }
        button {
          margin-top: 42px;
          width: 100%;
        }
      }
    }
  }
  .registerForm {
    width: 530px;
    max-width: calc(100% - 30px);
    padding: 63px 20px 46px;
    background: #ffffff;
    border-radius: 3px;
    position: relative;
    .backButton {
      position: absolute;
      top: 10px;
      left: 20px;
      padding: 5px 10px 5px 18px;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      color: #999999;
      cursor: pointer;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: 0px;
        width: 6px;
        height: 6px;
        border-left: 2px solid #999999;
        border-top: 2px solid #999999;
        transform: translate(50%, 100%) rotate(-45deg);
      }
    }
    .formContent {
      width: 100%;
      max-width: 355px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .formTitle {
        width: 100%;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #666666;
      }
      label {
        margin: 10px 0px 2px;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        color: #666666;
      }
      .textInput {
        width: 100%;
      }
      button {
        width: 100%;
        margin-top: 22px;
        font-size: 16px;
      }
      p {
        font-size: 12px;
        line-height: 2.5;
        margin-top: 15px;
        color: #666666;
        span {
          color: $primaryColor;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .registerView {
    .registerLogo {
      margin-bottom: 42px;
    }
    .registerContent {
      .registerTitle {
        font-size: 32px;
        line-height: 38px;
      }
      .registerSelect {
        flex-direction: column;
      }
    }
    .registerForm {
    }
  }
}
