@import '../../_variables.scss';

.stepperView {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 0px 19px;
  overflow: auto;
  &.centered {
    justify-content: center;
    .itemContainer {
      justify-content: center;
    }
  }
  .itemContainer {
    width: 25%;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .stepItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 9px 9px;
    span {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 15px;
      background-color: #AAAAAA;
      font-family: Tiempo;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
    label {
      width: 80px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
    }
    &.active {
      span {
        background-color: $primaryColor;
      }
      label {
        color: $primaryColor;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .stepperView {
    &.centered {
      justify-content: flex-start;
      .itemContainer {
        justify-content: flex-start;
      }
    }
  }
}
