@import '../../_variables.scss';

.postForm {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 30px;
  .startPost {
    height: 60px;
    background: #f5f5f5;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 70px;
    font-size: 18px;
    line-height: 200%;
    display: flex;
    align-items: center;
    color: #999999;
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.7;
    }
    p {
      padding-left: 30px;
    }
  }
  .authorContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .commentAvatar {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      &.noAvatar:before {
        width: 50px;
        height: 50px;
      }
    }
    .startPost {
      width: calc(100% - 80px);
    }
  }
  .dataBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media all and (max-width: $break-point-xs) {
      display: block;
      padding-left: 0;
    }
    .selectItem {
      flex: 0 50%;
      display: flex;
      justify-content: baseline;
    }
    .dataItem {
      width: 100%;
      flex: 0 50%;
      display: flex;
      justify-content: baseline;
      .uploadContainer {
        display: inline-block;
        display: flex;
        justify-content: baseline;
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          opacity: 0.7;
        }
      }
      .postAddCommentImage {
        display: flex;
        align-items: center;
        &:before {
          content: '';
          display: block;
          width: 21px;
          height: 17px;
          background-color: $primaryColor;
          mask: url(../../../../public/images/icons/image.svg) no-repeat center /
            contain;
        }
      }
      .selectInput {
        width: 70%;
        display: block;
        .reactSelect {
          width: 100% !important;
          display: block;
          div {
            background-color: #f5f5f5;
            border-radius: 10px;
          }
          span {
            background-color: #f5f5f5;
          }
          .reactSelect__option--is-selected {
            color: #ffffff;
            background-color: $primaryColor;
            div {
              background-color: $primaryColor;
            }
          }
        }
      }
    }
  }
  .uploadPhoto {
    padding-left: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 257%;
    display: flex;
    align-items: center;
    color: #999999;
  }
  .postingAs {
    font-weight: bold;
    font-size: 14px;
    line-height: 257%;
    display: flex;
    align-items: center;
    color: #999999;
    padding-right: 10px;
  }
  input[type='file'] {
    display: none;
  }
  .fileInfo {
    display: flex;
    align-items: center;
    padding: 10px 15px;
  }
}
