@import '../_variables.scss';

.manageCardView {
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin-top: 24px;
  &.embedded {
    padding: 0;
    min-height: initial;
    border: none;
    border-radius: 0px;
    width: 100%;
    margin-top: 38px;

    .stripeCardForm {
      margin-bottom: 0;
      .StripeElement,
      input {
        background: $white;
        border: 1px solid $outlineColor;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 13px 12px;
        margin-bottom: 13px;
      }
      input {
        height: 50px;
      }
    }

    button {
      width: 100%;
    }
  }
  .cardContainer {
    margin: 40px 20px;
    text-align: center;
    display: inline-block;
    button {
      margin: 25px 0px 0px;
    }
  }
  .cardInfo {
    margin-top: 15px;
    position: relative;
    padding-left: 90px;
    .cardLabel {
      margin-left: -56px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      transform: translateY(-50%);
    }
    &.selected {
      margin-left: 40px;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -40px;
        display: block;
        transform: translateY(-50%);
        width: 19px;
        height: 14px;
        background-color: #52c41a;
        mask: url(../../../public/images/icons/tick.svg) no-repeat center /
          contain;
      }
    }
    &.Visa {
      &::before {
        width: 48px;
        height: 30px;
        background: url(../../../public/images/visa@2x.png) no-repeat center;
        background-size: 48px 30px;
      }
    }
    &.MasterCard {
      &::before {
        width: 48px;
        height: 30px;
        background: url(../../../public/images/mastercard@2x.png) no-repeat
          center;
        background-size: 48px 30px;
      }
    }
    .stripeCardForm {
      margin: 0 0 35px;
    }
    button {
      width: 100%;
    }
  }
  .cardButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 465px;
    padding: 25px 0px 0px;
    .cardButton {
      padding: 10px 5px;
      margin: 0px 10px;
      font-size: 14px;
      font-weight: 600;
      color: $primaryColor;
      text-transform: uppercase;
      cursor: pointer;
      &.faint {
        opacity: 0.5;
      }
    }
  }

  .creds {
    margin: 53px auto 6px;
    background-image: url(../../../public/images/stripe/creds.png);
    max-width: 100%;
    width: 341px;
    height: 28px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 0;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url(../../../public/images/stripe/creds@2x.png);
    }
    &.webp {
      background-image: url(../../../public/images/stripe/creds.webp);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(../../../public/images/stripe/creds@2x.webp);
      }
    }
  }
}
