@import '../mixins.scss';
@import '../variables.scss';

.app.claim-profiles-page {
  background-image: url(../../../public/images/browse/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;

  @media all and (max-width: $break-point-xs) {
    background-image: none;
  }

  .appContent {
    background-color: transparent;
    .headerView {
      .headerLogo {
        height: 46px;
        width: 171px;
        margin-right: 22px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        background-image: url(../../../public/images/logo_white.png);
        background-size: 171px 46px;
        background-repeat: no-repeat;
        background-position: 0 center;

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          background-image: url(../../../public/images/logo_white@2x.png);
        }

        &.dark {
          background-image: url(../../../public/images/logo_black.png);

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../../public/images/logo_black@2x.png);
          }
        }

        @media all and (max-width: $break-point-xs) {
          background-image: url(../../../public/images/logo_black.png);

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../../public/images/logo_black@2x.png);
          }
        }
      }

      .headerSearch {
        background-color: rgba(255, 255, 255, 0.2);
        input {
          color: #aaa;
        }
      }

      .headerNavigation {
        .navItem {
          color: #fff;
          transition: all 0.2s linear;

          @media all and (max-width: $break-point-xs) {
            color: $secondaryTextColor;
          }

          &:hover,
          &.active,
          &.button {
            color: $primaryColor;
          }
        }
      }
    }
  }
  .labelText {
    font-weight: bold;
    margin: '10px 10px';
  }
}

.submitClaimBtn {
  width: 184px;
  height: 50;
  border-radius: 55px;
}

.profileClaim {
  display: flex;
  margin: 10px 0 4px;
  margin-bottom: 22px;

  .profileImage {
    width: 120px;
    height: 120px;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;

    &.noAvatar {
      background-color: $outlineColor;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        width: 100px;
        height: 100px;
        background-color: #fff;
        mask: url(../../../public/images/icons/profile.svg) no-repeat
          center / contain;
      }
    }
  }

  .authorNameTime {
    width: 100%;

    .authorName {
      margin-top: 30px;
      font-weight: 400;
      font-size: 42px;
      color: #333333;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      .subscribed {
        margin-left: 5px;
        height: 13px;
        width: 26px;
        //background-image: url(../../../public/images/home/pro.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .postDate {
      font-size: 10px;
      color: $thirdTextColor;
    }
  }
}
.registerForm{
  margin: 25px;
  .selectInput{
    margin: 10px 1px;
  }
  .textInput{
    margin: 10px 1px;
  }
  textarea {
    height: 300px;
  }
}
.wish_div{
  width: 354px;
}