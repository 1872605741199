@import '../../_variables.scss';

.snackbar {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 250px;
  z-index: 91301;
  text-align: center;
  transition: bottom 0.25s ease 0s;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  &:not(.show) {
    bottom: -300px;
  }
  &.success {
    .snackbarContainer {
      background-color: $successColor;
    }
  }
  &.error {
    .snackbarContainer {
      background-color: $errorColor;
    }
  }
  .snackbarOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.3;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  }
  .snackbarContainer {
    position: relative;
    background-color: #333333;
    width: calc(100% - 36px);
    display: inline-flex;
    align-items: center;
    padding: 10px 45px 10px 20px;
    border-radius: 10px;
    .snackbarText {
      font-size: 14px;
      line-height: 20px;
      min-height: 40px;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      text-align: left;
    }
    .snackbarClose {
      position: absolute;
      right: 12px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background-color: #ffffff;
      mask: url(../../../../public/images/icons/cross.svg) no-repeat center;
      mask-size: 14px;
    }
  }
}

@media all and (min-width: 768px) {
  .snackbar {
    .snackbarContainer {
      width: 480px;
    }
  }
}
