@import '../../variables.scss';
@import '../../mixins.scss';

.goalsSelect {
  width: 100%;
  .goalsTable {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1px;
    .goalCell {
      box-shadow: 0 0 0 1px #cccccc;
      padding-top: 120%;
      position: relative;
      cursor: pointer;
      border-top: 10px solid #dddddd;
      color: #999999;
      background-color: $mainBackgroundColor;
      &.selected {
        background-color: #ffffff;
      }
      &:not(.selected) {
        color: #999999 !important;
        border-color: #dddddd !important;
        .goalCellContent {
          .goalImage {
            opacity: 0.5;
          }
        }
      }
    }
    .goalCellContent {
      @include absolute-cover;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: inherit;
      background-size: 0px;
      background-repeat: no-repeat;
      background-position: center calc(50% + 15px);
      label {
        text-transform: uppercase;
        z-index: 1;
      }
      .selectedIndicator {
        position: absolute;
        right: 14px;
        bottom: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .goalImage {
        position: absolute;
        top: 52px;
        left: calc(50% - 50px);
        width: 100px;
        height: 100px;
        background-color: #666666;
        &.no-poverty {
          mask: url(../../../../public/images/goals/poverty.png) no-repeat
            center;
          mask-size: 81px;
        }
        &.zero-hunger {
          mask: url(../../../../public/images/goals/hunger.png) no-repeat center;
          mask-size: 45px;
        }
        &.good-health-and-well-being {
          mask: url(../../../../public/images/goals/health.png) no-repeat center;
          mask-size: 43px;
        }
        &.quality-education {
          mask: url(../../../../public/images/goals/education.png) no-repeat
            center;
          mask-size: 45px;
        }
        &.gender-equality {
          mask: url(../../../../public/images/goals/gender_equality.png)
            no-repeat center;
          mask-size: 28px;
        }
        &.clean-water-and-sanitation {
          mask: url(../../../../public/images/goals/water.png) no-repeat center;
          mask-size: 27px;
        }
        &.affordable-and-clean-energy {
          mask: url(../../../../public/images/goals/energy.png) no-repeat center;
          mask-size: 45px;
        }
        &.decent-work-and-economic-growth {
          mask: url(../../../../public/images/goals/economic_growth.png)
            no-repeat center;
          mask-size: 45px;
        }
        &.industry-innovation-and-infrastructure {
          mask: url(../../../../public/images/goals/infrastructure.png)
            no-repeat center;
          mask-size: 45px;
        }
        &.reduced-inequalities {
          mask: url(../../../../public/images/goals/inequality.png) no-repeat
            center;
          mask-size: 45px;
        }
        &.sustainable-cities-and-communities {
          mask: url(../../../../public/images/goals/cities.png) no-repeat center;
          mask-size: 45px;
        }
        &.responsible-consumption-and-production {
          mask: url(../../../../public/images/goals/consumption.png) no-repeat
            center;
          mask-size: 45px;
        }
        &.climate-action {
          mask: url(../../../../public/images/goals/climate.png) no-repeat
            center;
          mask-size: 45px;
        }
        &.life-below-water {
          mask: url(../../../../public/images/goals/oceans.png) no-repeat center;
          mask-size: 45px;
        }
        &.life-on-land {
          mask: url(../../../../public/images/goals/biodiversity.png) no-repeat
            center;
          mask-size: 45px;
        }
        &.peace-justice-and-strong-institutions {
          mask: url(../../../../public/images/goals/peace.png) no-repeat center;
          mask-size: 45px;
        }
        &.partnerships-for-the-goals {
          mask: url(../../../../public/images/goals/partnerships.png) no-repeat
            center;
          mask-size: 45px;
        }
        &.other_sustainability_solutions {
          mask: url(../../../../public/images/goals/other_sustainability_solutions.png) no-repeat
          center;
          mask-size: 45px;
        }
      }
      &.infrastructure {
        background-image: url(../../../../public/images/home/goal_infrastructure.png);
      }
    }
  }
}

@media all and (max-width: 768px) {
  .goalsSelect {
    .goalsTable {
      grid-template-columns: repeat(2, 1fr);
      .goalCell {
        .goalCellContent {
          padding: 8px;
          label {
            font-size: 12px;
          }
          .goalImage {
            top: 44px;
          }
        }
      }
    }
  }
}
