@import '../../variables.scss';
@import '../../mixins.scss';

.multicheckInput {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  .checkItem {
    position: relative;
    background-color: $mainBackgroundColor;
    color: #666666;
    border-radius: 3px;
    padding: 12px 25px;
    cursor: pointer;
    &.selected {
      background-color: $primaryColor;
      color: #FFFFFF;
    }
    .itemTitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: inherit;
    }
    .itemSubtitle {
      margin-top: 6px;
      font-size: 12px;
      line-height: 16px;
      color: inherit;
    }
    .selectedIndicator {
      position: absolute;
      top: 14px;
      right: 21px;
    }
  }
}

@media all and (max-width: 768px) {
  .multicheckInput {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    .checkItem {
      padding: 15px 20px;
    }
  }
}
