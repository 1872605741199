@import '../_variables.scss';

.restorePasswordView {
  background-color: $white;
  color: $secondaryTextColor;
  padding: 30px;
  border-radius: 3px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);

  .textInput {
    margin-bottom: 20px;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
  .inputLabel {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;
  }
  .separationLine {
    border-bottom: 1px solid $lightBackgroundColor;
  }
  .actionBtn {
    font-weight: 700;
    font-size: 14px;
    max-width: 200px;
    @media all and (max-width: $break-point-xs) {
      max-width: 100%;
    }
  }
  .row {
    display: flex;
    @media all and (max-width: $break-point-xs) {
      flex-direction: column;
    }
  }
  .col-50 {
    width: 50%;
    padding: 0 25px;
    @media all and (max-width: $break-point-xs) {
      width: 100%;
    }
  }
}
