@import '../mixins.scss';
@import '../variables.scss';

.app.browse-page {
  background-image: url(../../../public/images/browse/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;

  @media all and (max-width: $break-point-xs) {
    background-image: none;
  }

  .appContent {
    background-color: transparent;
    .headerView {
      .headerLogo {
        height: 46px;
        width: 171px;
        margin-right: 22px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        background-image: url(../../../public/images/logo_white.png);
        background-size: 171px 46px;
        background-repeat: no-repeat;
        background-position: 0 center;

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          background-image: url(../../../public/images/logo_white@2x.png);
        }

        &.dark {
          background-image: url(../../../public/images/logo_black.png);

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../../public/images/logo_black@2x.png);
          }
        }

        @media all and (max-width: $break-point-xs) {
          background-image: url(../../../public/images/logo_black.png);

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../../public/images/logo_black@2x.png);
          }
        }
      }

      .headerSearch {
        background-color: rgba(255, 255, 255, 0.2);
        input {
          color: #aaa;
        }
      }

      .headerNavigation {
        .navItem {
          color: #fff;
          transition: all 0.2s linear;

          @media all and (max-width: $break-point-xs) {
            color: $secondaryTextColor;
          }

          &:hover,
          &.active,
          &.button {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.browseView {
  width: 100%;
  margin-top: 60px;

  @media all and (max-width: $break-point-xs) {
    margin-top: 20px;
  }

  .header {
    font-family: 'Tiempo';
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    margin-bottom: 16px;

    @media all and (max-width: $break-point-xs) {
      color: $secondaryTextColor;
    }
  }

  .subheader {
    font-size: 24px;
    line-height: 30px;
    color: $secondaryTextColor;
    margin-bottom: 20px;
  }

  section {
    background: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 25px 35px;
    margin-bottom: $spaceMain;
  }

  .banner {
    margin-bottom: $spaceMain;
    min-width: 728px;
    min-height: 90px;
    text-align: center;

    &.mobileBanner {
      min-width: 320px;
      min-height: 50px;
    }
  }

  .activeUsers {
    .activeUsersContainer {
      margin: -14px;
      display: flex;

      @media all and (max-width: $break-point-xs) {
        margin-left: 0;
        margin-right: 0;
        flex-wrap: wrap;
      }

      .profileCard {
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          filter: brightness(97%);
        }
      }
    }

    .userPlaceholderContainer {
      display: flex;

      .userPlaceholderItem {
        position: relative;
        width: 25%;
      }
    }
  }

  .trendingPostsContainer {
    margin: -10px;
    display: flex;

    @media all and (max-width: $break-point-xs) {
      margin-left: 0;
      margin-right: 0;
      flex-wrap: wrap;
    }

    .postContentPlaceholder {
      width: 33%;
      background: $white;
      margin-bottom: 20px;
    }

    .trendingPost {
      width: calc(100% / 3);
      margin: 10px;

      @media all and (max-width: $break-point-xs) {
        width: 100%;
        flex-shrink: 0;
        margin: 10px 0;
      }

      .postCategory {
        font-size: 12px;
        color: $secondaryTextColor;
        border-bottom: 1px solid $lightBackgroundColor;
        padding-bottom: 7px;

        span {
          font-weight: 700;
          color: $primaryColor;
          cursor: pointer;
        }
      }

      .authorMeta {
        display: flex;
        margin: 10px 0 4px;

        .profileImage {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          flex-shrink: 0;
          cursor: pointer;

          &.noAvatar {
            background-color: $outlineColor;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              display: block;
              transform: translateX(-50%);
              width: 25px;
              height: 25px;
              background-color: #fff;
              mask: url(../../../public/images/icons/profile.svg) no-repeat
                center / contain;
            }
          }
        }

        .authorNameTime {
          width: 100%;

          .authorName {
            font-weight: bold;
            font-size: 12px;
            color: $secondaryTextColor;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            .subscribed {
              margin-left: 5px;
              height: 13px;
              width: 26px;
              //background-image: url(../../../public/images/home/pro.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }

          .postDate {
            font-size: 10px;
            color: $thirdTextColor;
          }
        }
      }

      .postComment {
        font-size: 14px;
        line-height: 20px;
        color: $secondaryTextColor;
        word-break: break-word;
        white-space: pre-line;
      }

      .postDetails {
        border: 1px solid $lightBackgroundColor;
        border-radius: 3px;
        line-height: 18px;
        color: $thirdTextColor;
        margin-top: 10px;
        cursor: pointer;

        img {
          max-width: 100%;
          display: block;
          border-radius: 3px 3px 0 0;
        }

        .postTitle {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: $secondaryTextColor;
          margin: 10px 14px 0 14px;
        }

        .postSubtitle {
          font-size: 12px;
          margin: 10px 14px 0 14px;
        }

        .postLink {
          font-weight: bold;
          font-size: 10px;
          margin: 5px 14px 0 14px;
        }

        & > div:last-child {
          margin-bottom: 10px;
        }
      }

      .postMeta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        line-height: 20px;
        color: $thirdTextColor;
        margin-top: 10px;

        .postCommentsCount {
          &:after {
            content: '';
            display: inline-block;
            width: 14.6px;
            height: 10px;
            background-color: $outlineColor;
            mask: url(../../../public/images/icons/comment.svg) no-repeat center /
              contain;
            margin-left: 6px;
          }
        }
      }
    }
  }

  .sdgGoalsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1px;

    @media all and (max-width: $break-point-xs) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .targetActivitiesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;

    @media all and (max-width: $break-point-xs) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 15px;
    }
  }
}
