@import '../variables.scss';

.subscribeView {
  background-color: $white;
  border-radius: $radiusMain;
  background-image: url('../../../public/images/pro.png');
  background-size: 310px;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 110px;

  @media all and (max-width: $break-point-sm) {
    padding-top: 120px;
  }

  .header {
    .mainText {
      font-family: Tiempo;
      font-weight: 700;
      font-size: 30px;
      line-height: 120%;
      text-align: center;
      color: $secondaryTextColor;
    }
    .secondaryHeader {
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 114%;
      text-align: center;
      color: $secondaryTextColor;
      margin-top: 10px;
    }
  }

  .duration {
    display: flex;
    justify-content: center;
    padding-top: 29px;
    padding-bottom: 29px;
  }

  .durationInput {
    display: inline-block;
    height: 40px;
    width: 200px;
    cursor: pointer;
    user-select: none;
    background-color: $lightBackgroundColor;
    pointer-events: auto;
    border-radius: 60px;
    .switcher {
      display: flex;
      flex-direction: row;
      .durationOption {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 38px;
        position: relative;
        top: 1px;
        left: 1px;
        border-radius: 60px;
        span {
          color: $thirdTextColor;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 38px;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 14px;
          line-height: 257%;
          text-align: center;
        }
      }
      .activeDurationOption {
        background-color: $primaryColor;
        span {
          color: $white;
        }
      }
    }
  }

  .subscriptionContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 47px;
    @media all and (max-width: $break-point-xs) {
      flex-direction: column;
      align-items: center;
      padding: 20px 20px 47px;
    }
  }

  .subscriptionItem {
    flex-grow: 1;
    max-width: 250px;
    border: 1px solid $lightBackgroundColor;
    position: relative;
    &:nth-child(2) {
      border-left: none;
      border-right: none;
    }
    @media all and (max-width: $break-point-xs) {
      width: 100%;
      max-width: 320px;
      margin-bottom: 20px;
      &:nth-child(2) {
        border-left: 1px solid $lightBackgroundColor;
        border-right: 1px solid $lightBackgroundColor;
      }
    }
    .subscriptionHeader {
      background-color: $mainBackgroundColor;
      border-bottom: 1px solid $lightBackgroundColor;
      padding: 13px 0 13px 18px;
      .subscriptionType {
        font-family: Tiempo;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        color: $secondaryTextColor;
      }
      .accountType {
        font-family: 'Open Sans';
        font-size: 14px;
        color: $secondaryTextColor;
      }
    }

    .paidHeader {
      .subscriptionType {
        font-family: Tiempo;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        color: #0c97dc;
      }
      .accountType {
        font-family: 'Open Sans';
        font-size: 14px;
        color: #0c97dc;
        span {
          text-transform: capitalize;
        }
      }
    }

    .price {
      padding: 13px 0 13px 18px;
      border-bottom: 1px solid $lightBackgroundColor;
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 18px;
      line-height: 200%;
      color: $secondaryTextColor;
      display: flex;
      flex-direction: column;
      min-height: 100px;
      .secondaryPrice {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 300%;
        color: $thirdTextColor;
      }
    }

    .checkmarkList {
      list-style: none;
      padding-inline-start: 18px;
      margin-bottom: 96px;
      li {
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 233%;
        color: $secondaryTextColor;
        margin-bottom: 10px;
        @media all and (max-width: 768px) {
          font-size: 16px;
        }
        &:before {
          content: '✓';
          padding-right: 8px;
        }
      }
      &.paidCheckmarkList {
        li:before {
          color: #279948;
        }
      }
      .subList {
        list-style-type: disc;
        color: $thirdTextColor;
        li {
          margin-bottom: 0;
          &:before {
            content: none !important;
          }
        }
      }
    }

    .subscribe {
      display: flex;
      justify-content: center;
      width: calc(100% - 36px);
      position: absolute;
      bottom: 0;
      left: 18px;
      right: 18px;
      &.disabled {
        button {
          opacity: 0.4;
          cursor: default;
          &[disabled] {
            color: $white;
          }
        }
      }
      button {
        padding: 4px 50px;
        margin-top: 8px;
        margin-bottom: 18px;
        background: $primaryColor;
        border-radius: $radiusMain;
        font-size: $fontSizeMain;
        font-weight: $fontWeightBold;
        width: 100%;
      }
      .alreadySubscribed {
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 18px;
        color: $secondaryTextColor;
      }
    }
  }
}

.popupContent {
  .popupTitle {
    color: $secondaryTextColor !important;
    line-height: 167% !important;
    font-size: $fontSizeHeader;
    text-align: center;
  }

  .subscriptionInfo {
    font-size: $fontSizeHeader;
    line-height: 167%;
    padding: $spaceHorizontal 0 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $lightBackgroundColor;
    .subscriptionPrice {
      color: $primaryColor;
    }

    .subscriptionPlan {
      text-align: right;
      color: $thirdTextColor;
      text-transform: capitalize;
    }
  }
}
