@import '../_variables.scss';

.authView {
  width: 100%;
  padding: 40px 20px;
  margin: 0 auto;
  color: $secondaryTextColor;
  position: relative;

  @media all and (max-width: $break-point-xs) {
    padding: 30px 30px 10px;
    width: auto;
  }

  .authInput {
    height: 50px;
    margin-bottom: 20px;
    border: 1px solid #CCC;
    position: relative;
    border-radius: 3px;

    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      padding: 0 20px;
      font-size: 18px;
      &::placeholder {
        color: #CCC;
      }
    }
  }

  .authBack {
    position: absolute;
    left: 10px;
    top: 0;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $thirdTextColor;

    .authBackArrow {
      margin-right: 5px;
    }
  }

  .authInputLabel {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    margin-bottom: 10px;
  }

  .authForgotPass {
    margin-bottom: 40px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  .authTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.22;
    margin-bottom: 40px;
    text-align: center;
  }

  .authBtn {
    border: none;
    color: white;
    width: 100%;
    font-weight: 700;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 3px;
    padding: 14px 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    transition: all 0.2s linear;
    background: $primaryColor;
    &:hover {
      opacity: 0.85;
    }
  }

  .authInputError {
    border: solid 1px red;
  }
}
