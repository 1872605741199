@import '../../_variables.scss';

.stripeCardForm {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 25px;
  label {
    margin: 20px 0 5px;
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #666666;
    text-transform: capitalize;
  }
  input {
    display: block;
    width: 100%;
    margin: 0;
    border: solid 1px $outlineColor;
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px 12px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #333333;
    &::placeholder {
      font-weight: 500;
      color: #BFBFBF;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    .column {
      width: calc(50% - 9px);
    }
  }
  &.embedded {
    .column {
      width: calc(50% - 40px);
      &.expiryLabel {
        font-weight: $fontWeightBold;
        font-size: $fontSizeMain;
        line-height: 214%;
        color: $secondaryTextColor;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 13px;
        width: 56px;
      }
    }
  }
}

.StripeElement {
  display: block;
  width: 100%;
  margin: 0;
  border: solid 1px $outlineColor;
  background-color: #FFF;
  border-radius: 10px;
  padding: 8px 12px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #333333;
}

.StripeElement--focus {
}

.StripeElement.PaymentRequestButton {
}
