@import '../mixins.scss';
@import '../variables.scss';

.editInterestsView {
  width: 100%;
  &.wizard {
  }
  .interestsContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .interestsTitle {
    margin-top: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #666666;
  }
  .interestsSubtitle {
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-bottom: 5px;
  }
  .goalsSelect {
    margin: 16px 0px;
  }
  .multicheckInput {
    margin: 16px 0px;
  }
  .buttonBlock {
    border-top: 1px solid #EEEEEE;
    padding-top: 32px;
    margin-top: 32px;
    button {
      width: 210px;
      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      color: #FFFFFF;
      text-transform: none;
    }
  }
}

@media all and (max-width: 768px) {
  .editInterestsView {
    .interestsContent {
    }
    .buttonBlock {
      button {
        width: 100%;
      }
    }
  }
}
