@import '../_variables.scss';

.chatView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  &.embedded {
    .chatContainer {
      border-radius: 0px;
      box-shadow: none;
    }
  }
  .limitReached {
    opacity: 0.4;
  }
  .switchChatButton {
    position: fixed;
    top: 6px;
    right: 6px;
    z-index: 500;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #333333;
    }
  }
  .videoRoomContainer {
    width: 100%;
    position: relative;
    & + .chatContainer {
      width: 325px;
      flex: none;
      border-left: 1px solid #dddddd;
    }
  }
  .chatContainer {
    flex: 1 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .messages {
      background-color: #f5f5f5;
      flex: 1 0;
      width: 100%;
      overflow-y: auto;
      padding: 24px 24px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      .messageBulk {
        padding: 8px 0px;
        .bulkAuthor {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #666666;
          margin-bottom: -1px;
        }
        .bulkTime {
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 10px;
          line-height: 300%;
          color: #aaaaaa;
          margin-left: 58px;
        }
        &.owned {
          .bulkAuthor,
          .bulkTime {
            margin-left: auto;
            text-align: right;
          }
        }
      }
      .messageContainer {
        padding: 6px 0px;
        display: flex;
        .messageAvatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .messageItem {
          position: relative;
          max-width: 80%;
          word-break: break-word;
          display: inline-block;
          text-align: left;
          padding: 13px 16px;
          font-size: 12px;
          line-height: 1.5;
          color: $secondaryTextColor;
          border-radius: 20px;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
          margin-left: 18px;
          background-color: #ffffff;
          &.owned {
            margin-left: auto;
            text-align: right;
            background-color: #f26e26;
            color: #fff;
          }
          &.searched {
            background-color: $highlightColor;
          }
          &.focused {
            //background-color: $color3;
          }
          .messageInfo {
            display: flex;
            flex-direction: row;
            padding-bottom: 8px;
            .messageTime {
              font-size: 12px;
              line-height: 16px;
              color: #666666;
              margin-right: 10px;
            }
            .messageAuthor {
              font-size: 14px;
              line-height: 16px;
              color: #666666;
              span {
                vertical-align: top;
              }
              .authorAvatar {
                display: inline-block;
                margin-left: 6px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
          .messageContent {
          }
          .fileLink {
            font-size: 15px;
            color: #333333;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      .messagePlaceholder {
        padding: 50px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 28px;
          margin-bottom: 20px;
        }
        label {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .messageInputContainer {
      position: relative;
      display: flex;
      height: 69px;
      .messageWrap {
        width: 100%;
        border: none;
        display: flex;
        background-color: white;
        box-shadow: 0 5px 20px 4px rgba(114, 114, 114, 0.11);
        &.notAvailable {
          padding: 10px;
          align-items: center;
          justify-content: center;
          color: #666666;
          font-weight: 500;
        }
      }
      textarea,
      .fakeTextArea {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-right: 18px;
        padding-left: 18px;
        width: calc(100% - 115px);
        line-height: 33px;
        resize: none;
        font-family: inherit;
        font-size: 14px;
        color: $secondaryTextColor;
        border: none;
        border-radius: 60px;
        border: 1px solid #cccccc;
        &::-webkit-scrollbar {
          width: 0px;
        }
        &::placeholder {
          color: #aaaaaa;
        }
      }
      .fakeTextArea {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        color: $secondaryTextColor;
        .upgradePlan {
          font-weight: 600;
          color: $primaryColor;
          cursor: pointer;
        }
      }
      .fileInfo {
        width: calc(100% - 165px);
        height: 100%;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
      }
      input[type='file'] {
        display: none;
      }
      .sendButton,
      .actionsButton,
      .removeButton,
      .attachFiles {
        flex-shrink: 0;
        width: 50px;
        padding: 9px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &:hover:not(.disabled) {
        }
        svg {
          fill: #333333;
        }
        span {
          color: #333333;
        }
        .messagePrice {
          display: inline;
          margin-right: 5px;
        }
        &.disabled {
          color: #999999;
          cursor: default;
          span,
          .messagePrice {
            color: #999999;
          }
        }
      }
      .sendButton {
        margin-right: 10px;
        svg {
          &:not(.disabled) {
            fill: #f26e26;
          }
        }
      }
      .attachFiles {
        margin-right: 5px;
        width: 50px;
        &.disabled {
          color: #999;
        }
      }
      .actionsButton {
        position: absolute;
        top: 0px;
        bottom: 0px;
      }
      .pendingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100%;
        float: left;
        border-left: 1px solid #f5f5f5;
        .pendingIndicator {
          position: relative;
          text-align: center;
          &::before,
          &::after {
            content: '';
            animation-fill-mode: both;
            position: absolute;
            left: -20px;
            top: -20px;
            border: 2px solid $primaryColor;
            border-bottom-color: transparent;
            border-top-color: transparent;
            border-radius: 100%;
            height: 35px;
            width: 35px;
            animation: indicator-frames 1s 0s ease-in-out infinite;
          }
          &::after {
            display: inline-block;
            top: -10px;
            left: -10px;
            width: 15px;
            height: 15px;
            animation-duration: 0.5s;
            border-color: $primaryColor transparent $primaryColor transparent;
            animation-direction: reverse;
          }
        }
      }
    }
    .partnerInfo {
      width: 100%;
      height: 83px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 22px;
      .backIcon {
        display: none;
        padding: 8px 0px;
        margin-right: 12px;
        color: #666666;
        cursor: pointer;
      }
      .partnerImage {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;
      }
      .partnerName {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        justify-content: center;
        span {
          cursor: pointer;
          font-family: 'Open Sans';
          font-weight: 700;
          font-size: 18px;
          line-height: 167%;
          color: #666666;
          &:hover {
            text-decoration: underline;
          }
        }
        .subscribed {
          margin-left: 10px;
          height: 17px;
          width: 35px;
          //background-image: url(../../../public/images/home/pro.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        label {
          font-weight: 500;
          color: #666666;
          font-size: 14px;
          line-height: 17px;
        }
      }
      .videoButton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        height: 30px;
        width: 40px;
        cursor: pointer;
        svg {
          stroke: $color5;
        }
      }
    }
    .searchContainer {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      .backIcon {
        display: none;
        padding: 8px;
        color: #333333;
        cursor: pointer;
      }
      .searchIcon {
        padding: 8px;
        color: #333333;
      }
      input {
        padding: 0px;
        min-width: 100px;
        border: none;
        flex: 1 0;
        font-size: 15px;
        color: #333333;
      }
      .searchControls {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        user-select: none;
        .searchSwitch {
          padding: 8px;
          color: #333333;
          cursor: pointer;
          &.disabled {
            cursor: default;
            color: #999999;
          }
          &:first-child {
            padding-right: 0px;
          }
          &:last-child {
            padding-left: 0px;
          }
        }
      }
      .resultLabel {
        flex-shrink: 0;
        width: 90px;
        padding: 12px;
        font-size: 12px;
        color: #666666;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .chatView {
    overflow: hidden;
    .chatContainer {
      background-color: #ffffff;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1025;
      transition: right 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      &.hidden {
        right: -100%;
      }
      .searchContainer {
        .backIcon {
          display: block;
        }
      }
      .partnerInfo {
        .backIcon {
          display: block;
        }
      }
      .messageLimit {
        padding: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        .upgradePlan {
          font-weight: 600;
          color: $primaryColor;
          cursor: pointer;
        }
      }
      .messages {
        padding: 20px 15px;
        .messageContainer {
          .messageItem {
            max-width: 85%;
            padding: 10px 15px;
            .messageInfo {
              .messageTime {
                font-size: 11px;
              }
              .messageAuthor {
                font-size: 12px;
                .authorAvatar {
                  height: 28px;
                  width: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes indicator-frames {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1.2);
  }
}
