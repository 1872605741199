@import '../../_variables.scss';

.confirmationPopup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  &.hidden {
    display: none;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .popupWindow {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgb(255, 255, 255);
      width: calc(100% - 60px);
      max-width: 480px;
      position: relative;
      max-width: 448px;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px, rgba(0, 0, 0, 0.06) 0 2px 12px,
        rgba(0, 0, 0, 0.04) 0 8px 14px, rgba(0, 0, 0, 0.02) 0 12px 16px;
      max-height: 100%;
      border-radius: 10px;
      transition: all 300ms ease 0s;
      padding: 30px 20px;
      position: relative;
      .confirmIcon {
        width: 42px;
        height: 42px;
        margin: 0 auto 20px;
        &.check-circle {
          background-color: #52c41a;
          // mask: url(../../../../public/images/icons/check-circle.svg) no-repeat center / contain;
        }
        &.reject-circle {
          background-color: #f5222d;
          // mask: url(../../../../public/images/icons/close-circle.svg) no-repeat center / contain;
        }
      }
      .confirmContent {
        width: 100%;
      }
      .confirmTitle {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        color: $color1;
      }
      .confirmBody {
        margin: 15px 0;
        text-align: center;
        color: $color1;
      }
      .popupControls {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        .popupControl {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
