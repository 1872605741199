/********* TestSohne *********/
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/TestSohne/TestSohne-Extralight.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/TestSohne/TestSohne-Light.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/TestSohne/TestSohne-Book.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/TestSohne/TestSohne-Strong.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/TestSohne/TestSohne-Semibold.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/TestSohne/TestSohne-Bolder.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/TestSohne/TestSohne-Bold.otf') format('opentype');
}
@font-face {
  font-family: TestSohne;
  font-style: normal;
  font-weight: 1000;
  src: url('/fonts/TestSohne/TestSohne-Extrabold.otf') format('opentype');
}

/********* Tiempo *********/
@font-face {
  font-family: Tiempo;
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Tiempo/Tiempo Regular.ttf') format('truetype');
}
@font-face {
  font-family: Tiempo;
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Tiempo/Tiempo Bold.ttf') format('truetype');
}

/******** OpenSans *********/
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/fonts/OpenSans/open-sans-v18-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/fonts/OpenSans/open-sans-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('/fonts/OpenSans/open-sans-v18-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/fonts/OpenSans/open-sans-v18-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/fonts/OpenSans/open-sans-v18-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/fonts/OpenSans/open-sans-v18-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
