@import './base.scss';

$triangle-size: 27px;
$button-padding-y: 18px;
$button-padding-x: 64px;
$company-list-margin-top: 33px;

.sdg-experts-text {
  font-size: 14px;
  margin-top: 24px;
}

.sdg-value-chain-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;

  @media all and (min-width: 768px) {
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: $company-list-margin-top + 24;
  }

  @media all and (min-width: 1024px) {
    flex-wrap: nowrap;
  }

  .sdg-value-chain-list {
    display: none;
    justify-content: flex-start;
    flex-grow: 1;

    @media all and (min-width: 768px) {
      display: flex;
    }

    @media all and (min-width: 1024px) {
      flex-grow: unset;
    }

    .sdg-value-chain-button {
      position: relative;
      padding-top: $button-padding-y;
      padding-bottom: $button-padding-y;
      padding-left: $button-padding-x;
      padding-right: ($button-padding-x / 2) + ($triangle-size / 2);
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 700;
      line-height: $button-padding-y;
      color: #999;
      border: none;
      background: #f5f5f5;
      border-radius: 3px;
      white-space: nowrap;

      &:first-child {
        padding-left: $button-padding-x / 2;
      }

      &:not(:first-child) {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 0;
          height: 0;
          border-top: $triangle-size solid transparent;
          border-bottom: $triangle-size solid transparent;
          border-left: $triangle-size solid #fff;
          transition: all 0.3s linear;
        }
      }

      &::after {
        position: absolute;
        top: 0;
        right: -($triangle-size - 2);
        content: '';
        width: 0;
        height: 0;
        border-top: $triangle-size solid transparent;
        border-bottom: $triangle-size solid transparent;
        border-left: $triangle-size solid #f5f5f5;
        transition: all 0.3s linear;
      }

      &.active {
        color: #fff;
        background: #f26e26;

        &::after {
          border-left: $triangle-size solid #f26e26;
        }
      }

      &:hover {
        opacity: 1;
      }

      &:not(:last-child) {
        margin-right: $triangle-size - 2;
      }
    }
  }

  .sdg-value-chain-dropdown-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    margin-top: 10px;

    @media all and (min-width: 768px) {
      margin-top: 0;
    }

    .sdg-dropdown-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @media all and (min-width: 768px) {
        width: auto;
      }

      label {
        font-size: 14px;
        font-weight: 600;
        color: #666666;
        margin-bottom: 5px;
      }

      .sdg-dropdown-select-container {
        width: 100%;
      }
    }
  }

  .sdg-value-chain {
    justify-content: flex-start;

    @media all and (min-width: 768px) {
      display: none;
    }
  }

  .sdg-value-chain-category {
    margin-top: 10px;
    justify-content: flex-start;

    @media all and (min-width: 1024px) {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}
