@import '../../variables.scss';
@import '../../mixins.scss';

.targetActivityItem {
  position: relative;
  background-color: $mainBackgroundColor;
  color: $secondaryTextColor;
  border-radius: 3px;
  padding: 12px 25px;
  cursor: pointer;
  &.selected {
    background-color: $primaryColor;
    color: #ffffff;
  }
  &.link {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 7px;
      background-color: $thirdTextColor;
      mask: url(../../../../public/images/icons/caret_right.svg) no-repeat
        center / contain;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      transition: all 0.1s linear;
    }
    &:hover:after {
      right: 12px;
    }
  }
  .itemTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: inherit;
  }
  .itemSubtitle {
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
    color: inherit;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .selectedIndicator {
    position: absolute;
    top: 14px;
    right: 21px;
  }
}

@media all and (max-width: 768px) {
  .targetActivityItem {
    padding: 15px 20px;
  }
}
