@import '../../_variables.scss';

.tabsView {
  position: relative;
  .tabsContainer {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .tabsList {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      .tabItem {
        font-size: 24px;
        line-height: 30px;
        color: $thirdTextColor;
        cursor: pointer;
        padding: 15px 1rem 16px;
        border-bottom: 1px solid $lightBackgroundColor;
        white-space: nowrap;
        z-index: 2;
        position: relative;
        flex-grow: 1;
        flex-basis: 0;
        text-align: center;
        &.active {
          font-weight: 600;
          color: $primaryColor;
          border-bottom: 2px solid $primaryColor;
          padding-bottom: 15px;
        }
      }
    }
  }
  &.compact {
    .tabsContainer {
      .tabsList {
        .tabItem {
          font-size: 12px;
          padding: 10px 0px;
          text-transform: uppercase;
        }
      }
    }  
  }
  &.left-aligned {
    .tabsContainer {
      border-bottom: 1px solid #eeeeee;
      .tabsList {
        justify-content: initial;
        .tabItem {
          flex-grow: 0;
          border-bottom: 2px solid transparent;
          &.active {
            border-bottom-color: $primaryColor;
          }
        }
      }
    }
  }
}
