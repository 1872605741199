@import '../mixins.scss';
@import '../variables.scss';

.directoryView {
  width: 100%;

  section {
    background: #FFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 25px 35px;
    margin-bottom: 20px;
  }

  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $lightBackgroundColor;
    margin-bottom: 26px;
    padding-bottom: 26px;

    @media all and (max-width: $break-point-xs) {
      flex-wrap: wrap;
    }

    .youSearched {
      font-weight: $fontWeightBold;
      font-size: $fontSizeTitle;
      line-height: 100%;
      color: $secondaryTextColor;
    }

    .resultsFound {
      font-size: $fontSizeMain;
      line-height: 171%;
      color: $secondaryTextColor;
    }

    .header {
      font-size: 30px;
      line-height: 30px;
      color: $secondaryTextColor;
      font-weight: $fontWeightBold;
      margin-bottom: 4px;
    }
    .subheader {
      font-size: 14px;
      line-height: 114%;
      color: $secondaryTextColor;
    }
  }

  .sectionFilters {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;

    @media all and (max-width: $break-point-xs) {
      flex-wrap: wrap;
    }

    .profileTypeSelect {
      display: flex;
      align-items: center;

      .selectButton {
        margin-left: 10px;
      }
    }
    .profilesTypeSelect {
      display: contents;
      // align-items: center;

      .selectButton {
        margin-left: 1px;
      }
    }
    

    .sortFilters {
      display: flex;
      align-items: center;
      width: 60%;

      @media all and (max-width: $break-point-xs) {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 30px;
      }

      .inputBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;

        @media all and (max-width: $break-point-xs) {
          margin-top: 30px;
        }

        label {
          font-size: 14px;
          line-height: 114%;
          color: $thirdTextColor;
          margin-bottom: 7px;
          flex-shrink: 0;
          width: 100%;

          @media all and (max-width: $break-point-xs) {
            width: 55px;
          }
        }

        & + .inputBlock {
          margin-left: 30px;

          @media all and (max-width: $break-point-xs) {
            margin-left: 0;
          }
        }

        .selectInput {
          width: 100%;
        }
      }
    }
  }
  .sortprofilesFilters {
    display: flex;
    align-items: flex-end;
    // justify-content: space-between;
    margin-bottom: 30px;

    @media all and (max-width: $break-point-xs) {
      flex-wrap: wrap;
    }

    .profileTypeSelect {
      display: flex;
      align-items: center;

      .selectButton {
        margin-left: 10px;
      }
    }
    .profilesTypeSelect {
      display: contents;
      // align-items: center;

      .selectButton {
        margin: 2px;
      }
    }
    

    .sortFilters {
      display: flex;
      align-items: center;
      width: 60%;

      @media all and (max-width: $break-point-xs) {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 30px;
      }

      .inputBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;

        @media all and (max-width: $break-point-xs) {
          margin-top: 30px;
        }

        label {
          font-size: 14px;
          line-height: 114%;
          color: $thirdTextColor;
          margin-bottom: 7px;
          flex-shrink: 0;
          width: 100%;

          @media all and (max-width: $break-point-xs) {
            width: 55px;
          }
        }

        & + .inputBlock {
          margin-left: 30px;

          @media all and (max-width: $break-point-xs) {
            margin-left: 0;
          }
        }

        .selectInput {
          width: 100%;
        }
      }
    }
  }

  .activeUsers {
    .activeUsersContainer {
      margin: -14px;
      display: flex;
      flex-wrap: wrap;
      min-height: 125px;

      @media all and (max-width: $break-point-xs) {
        margin-left: 0;
        margin-right: 0;
      }

      .profileCard {
        cursor: pointer;
      }
    }

    .userPlaceholderContainer {
      display: flex;

      .userPlaceholderItem {
        position: relative;
        width: 25%;
      }
    }
  }

  .loadMore {
    background: #FFFFFF;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: $radiusMain;
    font-weight: $fontWeightBold;
    font-size: $fontSizeMain;
    line-height: 257%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $thirdTextColor;
    min-height: 50px;
  }

  .paywall {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &.emptyListPaywall {
      padding-top: $spaceMain;
      .paywallGradient {
          height: 0;
      }
    }
    .paywallGradient {
      position: absolute;
      top: -127px;
      background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);;
      max-width: 965px;
      width: 100%;
      height: 127px;
    }
    .paywallHeader {
      font-weight: $fontWeightBold;
      font-size: $fontSizeTitle;
      line-height: 100%;
      text-align: center;
      color: $secondaryTextColor;
      padding-top: $spaceMain;
      padding-bottom: $spaceSmall;
    }
    .paywallText {
      font-size: $fontSizeMain;
      line-height: 171%;
      text-align: center;
      color: $secondaryTextColor;
      padding-bottom: 15px;
    }
    button {
      background: #F26E26;
      border-radius: $radiusMain;
      font-weight: $fontWeightBold;
      font-size: $fontSizeMain;
      line-height: 257%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .searchButton {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #FFF;
    font-size: $fontSizeMain;
    text-align: center;
    border-radius: 60px;
    padding: 1px 16px 2px;
    cursor: pointer;
    line-height: 257%;
    margin-left: 10px;
  }

  .banner {
    text-align: center;
  }
}
