@import '../../_variables.scss';

.feed {
  display: flex;

  @media all and (max-width: $break-point-xs) {
    flex-wrap: wrap;
  }

  .feedTimeline {
    width: calc(100% - 330px);

    @media all and (max-width: $break-point-xs) {
      width: 100%;
    }

    .dataBlock {
      margin-left: 80px;
      margin-top: 15px;
    }

    .postContentPlaceholder {
      width: 100%;
      background: $white;
      margin-bottom: 20px;
      padding-top: 20px;
      padding-left: 20px;
    }
  }

  .loadMore {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    font-weight: bold;
    font-size: 14px;
    line-height: 257%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #999999;
    min-height: 50px;
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.7;
    }
  }
}
