@import '../mixins.scss';
@import '../variables.scss';

.app.home-page {
  .headerView {
    .underlay {
      background: linear-gradient(#070716, #070716 50%, transparent);
    }
    .navToggle {
      &:before {
        background-color: #fff;
        box-shadow: 0 0.5em 0 0 #fff, 0 1em 0 0 #fff;
      }
    }
    .headerNavigation {
      .navItem {
        color: #fff;
        transition: all 0.2s linear;
        &:hover {
          color: $primaryColor;
        }

        @media all and (max-width: $break-point-xs) {
          color: $secondaryTextColor;

          &.button {
            margin-top: 30px;
            border: 1px solid $primaryColor;
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.homeView {
  display: flex;
  flex-direction: column;
  position: relative;

  .homeFooter {
    z-index: 1;
    @include absolute-cover;
    top: initial;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    .footerContent {
      position: relative;
      width: 100%;
      height: 80px;
      max-width: 1035px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      font-weight: 300;
      .copyright {
        opacity: 0.85;
        margin: 0px auto;
      }
      .leftLinks,
      .rightLinks {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 35%;
      }
      .leftLinks {
        margin-right: auto;
        justify-content: flex-start;
        .footerLink {
          margin-right: 8px;
          .faqLink{
            text-decoration: none;
            color: white;
          }
        }
      }
      .rightLinks {
        margin-left: auto;
        justify-content: flex-end;
        .footerLink {
          margin-left: 18px;
          .faqLink{
            text-decoration: none;
            color: white;
          }
        }
      }
      .footerLink {
        padding: 2px 4px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: all 0.2s linear;
        &:hover {
          opacity: 0.6;
        }
        &.sdg {
          height: 31px;
          background-image: url(../../../public/images/logo_all_white.png);
          background-size: 116px 31px;
          background-position: 0 center;
          background-repeat: no-repeat;
          width: 116px;
          margin-right: 56px;
          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../../public/images/logo_all_white@2x.png);
          }
        }
        &.twitter,
        &.facebook,
        &.instagram {
          width: 40px;
          height: 40px;
          background-position: center;
          background-repeat: no-repeat;
        }
        &.twitter {
          background-image: url(../../../public/images/home/twitter.svg);
        }
        &.facebook {
          background-image: url(../../../public/images/home/facebook.svg);
        }
        &.instagram {
          background-image: url(../../../public/images/home/instagram.svg);
        }
      }
    }
  }

  .welcomeBlock {
    position: relative;
    width: 100%;
    background-color: #102c43;
    display: flex;
    flex-direction: column;
    align-items: center;
    .welcomeBackground {
      @include absolute-cover;
      bottom: initial;
      padding-top: 56%;
      background-image: url(../../../public/images/home/welcome_bg.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center 0px;
      &::before {
        content: '';
        @include absolute-cover;
        background-color: rgba(0, 0, 0, 0.4);
      }
      &::after {
        content: '';
        @include absolute-cover;
        top: initial;
        height: 195px;
        background: linear-gradient(
          360deg,
          #102c43 12.5%,
          rgba(16, 44, 67, 0) 100%
        );
      }
    }
    .welcomeContent {
      position: relative;
      width: 100%;
      max-width: 1035px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-top: 255px;
      .welcomeHeader {
        width: 615px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .welcomePretitle {
          font-size: 16px;
          line-height: 34px;
          color: #ffffff;
          padding-right: 150px;
          background-image: url(../../../public/images/home/eco-business.png);
          background-size: 130px;
          background-repeat: no-repeat;
          background-position: right center;
          margin-top: 10px;
        }
        .welcomeTitle {
          width: 100%;
          font-family: Tiempo;
          font-weight: 600;
          font-size: 64px;
          line-height: 64px;
          color: #ffffff;
        }
      }
      .welcomeInfo {
        padding: 6px 0px;
        width: 425px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          font-size: 22px;
          line-height: 34px;
          color: #ffffff;
        }
        button {
          width: 235px;
          margin-top: 28px;
        }
      }
    }
    .welcomeAdditional {
      padding: 155px 0px 80px;
      position: relative;
      width: 100%;
      max-width: 1035px;
      display: flex;
      flex-direction: column;
      .welcomeSubtitle {
        font-family: Tiempo;
        font-weight: 600;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 30px;
      }
      .welcomeSteps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .welcomeStep {
          display: flex;
          flex-direction: column;
          width: 330px;
          .stepImage {
            width: 100%;
            padding-top: 58%;
            background: #eeeeee;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            margin-bottom: 23px;
            background-size: 100%;
            background-position: bottom right;
            background-repeat: no-repeat;
            &.step1 {
              background-image: url(../../../public/images/home/step1.png);
            }
            &.step2 {
              background-image: url(../../../public/images/home/step2.png);
            }
            &.step3 {
              background-image: url(../../../public/images/home/step3.png);
            }
          }
          .stepTitle {
            font-size: 18px;
            line-height: 28px;
            color: #ffffff;
            margin-bottom: 4px;
          }
          .stepDescription {
            font-size: 16px;
            line-height: 24px;
            color: #cccccc;
          }
        }
      }
    }
  }

  .goalsBlock {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .goalsBackground {
      @include absolute-cover;
      background-image: url(../../../public/images/home/goals_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &::before {
        content: '';
        @include absolute-cover;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .goalsContent {
      width: 100%;
      max-width: 1035px;
      position: relative;
      padding: 68px 0px 88px;
      .goalsTitle {
        font-family: Tiempo;
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        color: #ffffff;
      }
      .goalsDescription {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
        margin-top: 12px;
      }
      .goalsTable {
        margin-top: 45px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 1px;
        .goalCell {
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
          padding-top: 120%;
          position: relative;
          cursor: pointer;
        }
        .goalCellContent {
          @include absolute-cover;
          padding: 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #ffffff;
          background-size: 0px;
          background-repeat: no-repeat;
          background-position: center calc(50% + 15px);
          transition: all 0.15s linear;
          &.sdg {
            background-image: url(../../../public/images/sdg.png);
            background-repeat: no-repeat;
            background-size: 69%;
            background-position: center calc(50% - 5px);
            pointer-events: none;
          }
          span {
            box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.75);
            margin-bottom: 4px;
            min-width: 8px;
            text-align: center;
          }
          label {
            text-transform: uppercase;
          }
          &:hover {
            background-size: auto 47px;
            background-color: rgba(0, 0, 0, 0.5);
            &.no-poverty {
              background-image: url(../../../public/images/goals/poverty.png);
            }
            &.zero-hunger {
              background-image: url(../../../public/images/goals/hunger.png);
            }
            &.good-health-and-well-being {
              background-image: url(../../../public/images/goals/health.png);
            }
            &.quality-education {
              background-image: url(../../../public/images/goals/education.png);
            }
            &.gender-equality {
              background-image: url(../../../public/images/goals/gender_equality.png);
            }
            &.clean-water-and-sanitation {
              background-image: url(../../../public/images/goals/water.png);
            }
            &.affordable-and-clean-energy {
              background-image: url(../../../public/images/goals/energy.png);
            }
            &.decent-work-and-economic-growth {
              background-image: url(../../../public/images/goals/economic_growth.png);
            }
            &.reduced-inequalities {
              background-image: url(../../../public/images/goals/inequality.png);
            }
            &.sustainable-cities-and-communities {
              background-image: url(../../../public/images/goals/cities.png);
            }
            &.responsible-consumption-and-production {
              background-image: url(../../../public/images/goals/consumption.png);
            }
            &.climate-action {
              background-image: url(../../../public/images/goals/climate.png);
            }
            &.life-below-water {
              background-image: url(../../../public/images/goals/oceans.png);
            }
            &.life-on-land {
              background-image: url(../../../public/images/goals/biodiversity.png);
            }
            &.peace-justice-and-strong-institutions {
              background-image: url(../../../public/images/goals/peace.png);
            }
            &.partnerships-for-the-goals {
              background-image: url(../../../public/images/goals/partnerships.png);
            }
            &.industry-innovation-and-infrastructure {
              background-image: url(../../../public/images/goals/infrastructure.png);
            }
            &.other_sustainability_solutions {
              background-image: url(../../../public/images/goals/other_sustainability_home.png);
            }
            &.All {
              background-image: url(../../../public/images/goals/All_home.png);
            }
          }
        }
      }
    }
  }

  .impactBlock {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .impactBackground {
      @include absolute-cover;
      background-image: url(../../../public/images/home/impact_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &::before {
        content: '';
        @include absolute-cover;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    .impactContent {
      width: 100%;
      max-width: 1035px;
      position: relative;
      padding: 68px 0px 120px;
      .impactTitle {
        font-family: Tiempo;
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        color: #ffffff;
      }
    }
    .impactList {
      margin-top: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .impactItem {
        padding-top: 22px;
        display: flex;
        flex-direction: column;
        width: calc(33% - 13px);
        flex-shrink: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.75);
        &.connections span::after {
          background-image: url(../../../public/images/home/connections.svg);
        }
        &.profiles span::after {
          background-image: url(../../../public/images/home/profiles.svg);
        }
        &.countries span::after {
          background-image: url(../../../public/images/home/countries.svg);
        }
        span {
          font-family: Tiempo;
          font-weight: 600;
          font-size: 72px;
          line-height: 72px;
          letter-spacing: 0.06em;
          color: #ffffff;
          vertical-align: top;
          &::after {
            content: '';
            display: inline-block;
            vertical-align: top;
            width: 35px;
            height: 25px;
            margin: 14px 8px;
            background-repeat: no-repeat;
            background-position: 0px center;
          }
        }
        label {
          margin-top: 8px;
          font-size: 18px;
          font-weight: 300;
          line-height: 30px;
          color: #ffffff;
        }
      }
    }
  }

  .partnersBlock {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    .partnersContent {
      width: 100%;
      max-width: 1035px;
      position: relative;
      padding: 60px 0px 68px;
      .partnersTitle {
        font-family: Tiempo;
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        color: #333333;
      }
      .partnerList {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .partnetItem {
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          &.livability_challenge {
            width: 106px;
            height: 49px;
            background-image: url(../../../public/images/home/livability_challenge.png);
          }
          &.temasek_foundation {
            width: 131px;
            height: 37px;
            background-image: url(../../../public/images/home/temasek_foundation.png);
          }
          &.go4sdgs {
            width: 158px;
            height: 26px;
            background-image: url(../../../public/images/home/go4sdgs.png);
          }
          &.ixsa {
            width: 107px;
            height: 34px;
            background-image: url(../../../public/images/home/ixsa.png);
          }
          &.eb_impact {
            width: 143px;
            height: 38px;
            background-image: url(../../../public/images/home/eb_impact.png);
          }
          &.fundedhere {
            width: 138px;
            height: 46px;
            background-image: url(../../../public/images/home/fundedhere.png);
          }
          &.tembusu {
            width: 60px;
            height: 77px;
            background-image: url(../../../public/images/home/tembusu.png);
          }
          &.sg_alliance {
            width: 80px;
            height: 81px;
            background-image: url(../../../public/images/home/sg_alliance.png);
          }
        }
      }
    }
  }

  .manifestBlock {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    z-index: 2;
    .manifestBackground {
      @include absolute-cover;
      background-image: url(../../../public/images/home/manifest_bg.png);
      background-repeat: repeat;
      background-size: 500px;
      &::before {
        content: '';
        @include absolute-cover;
        background-color: rgba(34, 34, 34, 0.9);
      }
    }
    .manifestContent {
      width: 100%;
      max-width: 880px;
      position: relative;
      padding: 68px 0px 0px;
      margin-bottom: -85px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .manifestIcon {
        width: 33px;
        height: 33px;
        background-image: url(../../../public/images/home/sdg_icon.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .manifestTitle {
        font-family: Tiempo;
        font-weight: 600;
        font-size: 42px;
        line-height: 50px;
        text-align: center;
        color: #ffffff;
        span {
          font-weight: 400;
          font-size: 32px;
        }
      }
      .manifestSubtitle {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.2em;
        color: #ffffff;
        text-transform: uppercase;
      }
      .manifestFlyer {
        margin-top: 28px;
        position: relative;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
        padding: 12px;
        .flyerBackground {
          @include absolute-cover;
          bottom: initial;
          height: 348px;
          background-image: url(../../../public/images/home/manifest_flyer.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../../public/images/home/manifest_flyer@2x.jpg);
          }
          &.webp {
            background-image: url(../../../public/images/home/manifest_flyer.webp);
            @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
              background-image: url(../../../public/images/home/manifest_flyer@2x.webp);
            }
          }
          &::before {
            content: '';
            @include absolute-cover;
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
        .flyerContent {
          position: relative;
          padding: 348px 26px 28px;
          border: 1px solid rgba(204, 204, 204, 0.3);
          p {
            margin: 24px 0px;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #666666;
          }
          p + .flyerItem {
            margin-top: 40px;
          }
          .flyerItem {
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #aaaaaa;
              display: flex;
              flex-direction: row;
              align-items: center;
              &::before,
              &::after {
                content: '';
                width: 30px;
                margin: 0px 6px;
                border-bottom: 1px solid #eeeeee;
              }
            }
            p {
              margin: 0px;
              font-weight: 300;
              font-size: 16px;
              line-height: 30px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #666666;
            }
          }
          .findOutMore {
            margin-top: 40px;
            cursor: pointer;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
          .flyerIcon {
            width: 56px;
            height: 56px;
            margin: 22px auto 0px;
            background-image: url(../../../public/images/home/flyer_icon.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .joinBlock {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .joinBackground {
      @include absolute-cover;
      background-image: url(../../../public/images/home/join_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &::before {
        content: '';
        @include absolute-cover;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .joinContent {
      width: 100%;
      max-width: 450px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 230px 0px 210px;
      .joinTitle {
        font-family: Tiempo;
        font-weight: 600;
        font-size: 42px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
      }
      .joinSubtitle {
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
        margin-top: 20px;
      }
      button {
        margin-top: 30px;
        width: 345px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .homeView {
    .homeFooter {
      height: auto;
      .footerContent {
        height: auto;
        flex-direction: column;
        padding: 5px 25px 20px;
        .leftLinks {
          width: 100%;
          justify-content: center;
          padding: 10px 0px;
        }
        .rightLinks {
          width: 100%;
          flex-direction: column;
        }
        .copyright {
          width: 100%;
        }
      }
    }

    .welcomeBlock {
      .welcomeBackground {
        padding-top: 100%;
        background-size: cover;
      }
      .welcomeContent {
        flex-direction: column;
        align-items: center;
        padding: 240px 25px 30px;
        .welcomeHeader {
          width: 100%;
          .welcomePretitle {
            margin-bottom: 4px;
          }
          .welcomeTitle {
            font-size: 42px;
            line-height: 42px;
          }
        }
        .welcomeInfo {
          width: 100%;
          padding: 0px;
          margin-top: 25px;
          p {
            font-size: 18px;
            line-height: 26px;
          }
          button {
            width: 100%;
            max-width: 375px;
          }
        }
      }
      .welcomeAdditional {
        padding: 60px 0px 45px;
        .welcomeSubtitle {
        }
        .welcomeSteps {
          flex-direction: column;
          align-items: center;
          .welcomeStep {
            margin-bottom: 25px;
            .stepImage {
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .goalsBlock {
      .goalsContent {
        padding: 50px 5px 75px;
        .goalsTitle {
          font-size: 32px;
          line-height: 38px;
          padding: 0px 20px;
        }
        .goalsDescription {
          padding: 0px 20px;
        }
        .goalsTable {
          grid-template-columns: repeat(3, 1fr);
          .goalCell {
            .goalCellContent {
              padding: 8px;
              label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .impactBlock {
      .impactContent {
        padding: 50px 25px 50px;
        .impactList {
          flex-direction: column;
          .impactItem {
            width: 100%;
            padding-top: 2px;
            margin-bottom: 25px;
          }
        }
      }
    }

    .partnersBlock {
      .partnersContent {
        padding: 50px 25px 40px;
        .partnerList {
          flex-direction: column;
          align-content: center;
          .partnetItem {
            margin-bottom: 25px;
          }
        }
      }
    }

    .joinBlock {
      .joinContent {
        padding: 200px 25px 300px;
        .joinTitle {
          font-size: 36px;
          line-height: 40px;
        }
        .joinSubtitle {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}
