@import '../mixins.scss';
@import '../variables.scss';

.footerView {
  position:relative;
  width: 100%;
  height: 80px;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgba(153, 153, 153, 0.2);
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  color: #999999;
  .copyright {
    margin: 0px auto;
  }
  .leftLinks,
  .rightLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35%;
  }
  .leftLinks {
    margin-right: auto;
    justify-content: flex-start;
    .footerLink {
      margin-right: 8px;
      .faqLink{
        text-decoration: none;
        color: #999999;
      }
    }
  }
  .rightLinks {
    margin-left: auto;
    justify-content: flex-end;
    .footerLink {
      margin-left: 18px;
      .faqLink {
        text-decoration: none;
        color: #999999;
      }
    }
  }
  .footerLink {
    padding: 2px 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.6;
    }
    &.sdg {
      height: 31px;
      background-image: url(../../../public/images/logo_gray.png);
      background-size: 116px 31px;
      background-position: 0 center;
      background-repeat: no-repeat;
      width: 116px;
      margin-right: 56px;
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(../../../public/images/logo_gray@2x.png);
      }
    }
    &.twitter,
    &.facebook,
    &.instagram {
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      svg {
        color: #999999;
      }
    }
    &.twitter {
      background-image: url(../../../public/images/icons/twitter.svg);
    }
    &.facebook {
      background-image: url(../../../public/images/icons/facebook.svg);
    }
    &.instagram {
      background-image: url(../../../public/images/icons/instagram.svg);
    }
  }
}

@media all and (max-width: 768px) {
  .footerView {
    height: auto;
    flex-direction: column;
    padding: 5px 0px 20px;
    .leftLinks {
      width: 100%;
      justify-content: center;
      padding: 10px 0px;
    }
    .rightLinks {
      width: 100%;
      flex-direction: column;
    }
    .copyright {
      width: 100%;
    }
  }
}
