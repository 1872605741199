@import '../mixins.scss';
@import '../variables.scss';

.app.events-page, .app.directory-page, .app.add-directory-page {
  min-height: 100vh;

  .aboutView {
    min-height: 100vh;
  }

  iframe {
    border: 0;
  }

  .content a {
    color: #F26E26
  }
}

.headerView {
  .underlay {
    background: none;
  }

  .headerNavigation {
    a,
    span {
      color: $white;

      &:hover {
        color: $primaryColor;
      }

      @media all and (max-width: $break-point-sm) {
        color: $secondaryTextColor;
      }
    }

    .userNavItem {
      a,
      span {
        color: $secondaryTextColor;

        &:hover {
          color: $primaryColor;
        }
      }
    }

    .button {
      color: $primaryColor;
    }
  }
}

.app.profile, .app.event-page, .app.directory-page, .app.add-directory-page {
  .headerView {
    .underlay {
      background: none;
    }

    .headerNavigation {
      a,
      span {
        color: $white;

        &:hover {
          color: $primaryColor;
        }

        @media all and (max-width: $break-point-sm) {
          color: $secondaryTextColor;
        }
      }

      .userNavItem {
        a,
        span {
          color: $secondaryTextColor;

          &:hover {
            color: $primaryColor;
          }
        }
      }

      .button {
        color: $primaryColor;
      }
    }

    .searchInput {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

      input {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
        border-radius: 60px;
        @media all and (max-width: $break-point-sm) {
          box-shadow: none;
        }
      }
    }
  }
}

.app.profile-page, .app.user, .app.organizations-page, .app.chats-page, .app.chats-page, .app.login-page , .app.custom_header-page{
  .headerView {
    .underlay {
      background: none;
    }

    .headerNavigation {
      a,
      span {
        color: $secondaryColor;

        &:hover {
          color: $primaryColor;
        }

        @media all and (max-width: $break-point-sm) {
          color: $secondaryTextColor;
        }
      }

      .userNavItem {
        a,
        span {
          color: $secondaryTextColor;

          &:hover {
            color: $primaryColor;
          }
        }
      }

      .button {
        color: $primaryColor;
      }
    }

    .searchInput {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

      input {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
        border-radius: 60px;
        @media all and (max-width: $break-point-sm) {
          box-shadow: none;
        }
      }
    }
  }
}


.aboutView, .event-page, .add-directory-page, .directory-page {
  display: flex;
  flex-direction: column;
  position: relative;

  &.background {
    background-image: url(../../../public/images/about/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url(../../../public/images/about/bg@2x.jpg);
    }

    &.webp {
      background-image: url(../../../public/images/about/bg.webp);
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(../../../public/images/about/bg@2x.webp);
      }
    }

    .content {
      position: relative;
      width: 100%;
      max-width: 1035px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 111px;
      margin: 0 auto;

      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: $white;

        @media all and (max-width: $break-point-md) {
          margin: 30px 45px;
        }
      }

      .section {
        width: 100%;
        margin-top: 30px;

        p {
          line-height: 162%;
          color: $white;
          margin-bottom: 20px;

          @media all and (max-width: $break-point-md) {
            margin: 20px 45px 0 45px;
          }

          span {
            color: $primaryColor;
          }
        }

        .bigTextSize {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}
