@import './_variables.scss';

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/open-sans/open-sans-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans/open-sans-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/open-sans/open-sans-v20-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans/open-sans-v20-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/open-sans/open-sans-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans/open-sans-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
	height: 100%;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif !important;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5;
  overflow-y: auto;
	overflow-x: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
	overscroll-behavior: none;
	color: $mainTextColor;
	background-color: $mainBackgroundColor;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0px;
  padding: 0px;
}

* {
	box-sizing: border-box;
	outline: none;
}

.customScrollBar {
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: #eee;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: #ccc;
	}
}

.title {
	font-family: 'Tiempo';
	font-weight: 700;
}

button {
  border-radius: 3px;
  background-color: $primaryColor;
  text-align: center;
  border: none;
  padding: 8px 30px;
	font-size: 20px;
	line-height: 36px;
  color: #ffffff;
	font-weight: 500;
  cursor: pointer;
	transition: all 0.3s linear;

	&.shadow {
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	}

	&.outline {
		background-color: transparent;
		border: 1px solid $primaryColor;
		color: $primaryColor;
		padding: 9px 14px;
	}

	&.round {
		border-radius: 30px;
	}

	&.regularFont {
		font-size: $fontSizeMain;
	}

  &[disabled] {
    background-color: #dadada;
    color: #666666;
    cursor: not-allowed;
    box-shadow: none;
  }

	&:hover {
		opacity: 0.85;
	}
}

.app {
	width: 100%;
}

.appContent {
	vertical-align: top;
	background-color: $mainBackgroundColor;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	margin: 0px auto;
	max-width: 1075px;
	padding: 0px 20px;
	&.fullScreen {
		max-width: initial;
		padding: 0px;
	}
}

@media all and (max-width: 768px) {
}
