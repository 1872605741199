@import '../../_variables.scss';

.post {
  position: relative;
  color: $secondaryTextColor;
  font-size: $fontSizeMain;

  .postAvatar {
    margin-right: $spaceMedium;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.7;
    }
  }

  .postOptions {
    transition: opacity $transitionMain;
    position: absolute;
    top: $spaceMain;
    right: $spaceHorizontal;
    cursor: pointer;
    line-height: 1;
    opacity: 0.9;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }
  }

  .actionsNav {
    width: 100px;
    position: absolute;
    right: 30px;
    top: 45px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px 0 5px 5px;
    z-index: 6;
    background: $mainBackgroundColor;
    .actionsNavItem {
      cursor: pointer;
      font-size: $fontSizeMain;
      line-height: 36px;
      color: $secondaryTextColor;
      padding: 0 $spaceMain;
      &:hover {
        background: $white;
      }
    }
  }

  .commentPlaceholder {
    margin-left: 0;
  }

  .postAuthor {
    display: flex;
    align-items: center;
    margin-bottom: $spaceMedium;

    .profileImage {
      cursor: pointer;
    }
  }

  .postInfo {
    line-height: 1;

    .postAuthorName {
      margin-bottom: $spaceSmall;
      font-size: $fontSizeHeader;
      font-weight: $fontWeightBold;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: all 0.2s linear;
      &:hover {
        opacity: 0.7;
      }
      .subscribed {
        margin-left: 5px;
        height: 17px;
        width: 35px;
        //background-image: url(../../../../public/images/home/pro.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .postPublishTime {
      color: $thirdTextColor;
    }
  }

  .postContent {
    white-space: pre-line;
    margin-bottom: $spaceMain;
  }

  .postPreview {
    margin-bottom: $spaceMain;
  }

  .postStats {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spaceMain;
    margin-bottom: $spaceMain;
    border-bottom: 1px solid $lightBackgroundColor;
    color: $thirdTextColor;
    line-height: 1;

    .postStatsCommentIcon {
      margin-left: $spaceSmall;
      width: 19px;
    }
  }

  .postActions {
    margin-bottom: $spaceMain;
    line-height: 1;

    &:last-child {
      margin-bottom: 0;
    }

    .postActionButton {
      margin-right: $spaceHorizontal;
    }

    .postActionButtonActive {
      filter: invert(45%) sepia(100%) saturate(521%) hue-rotate(339deg)
        brightness(96%) contrast(97%);
    }
  }

  .postComments {
    margin-bottom: $spaceSmall;

    &:last-child {
      margin-bottom: 0;
    }

    .postCommentPrevious {
      transition: opacity $transitionMain;
      display: inline-block;
      margin-bottom: $spaceMain;
      line-height: 1;
      cursor: pointer;
      color: $thirdTextColor;
      font-weight: $fontWeightBold;
      opacity: 0.9;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 1;
      }
    }
  }

  .postCommentForm {
    display: flex;
    align-items: center;

    .postCommentAvatar {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: $radiusComplete;
      margin-right: $spaceMedium;
      object-fit: cover;
    }

    .postAddComment {
      width: 100%;
      min-height: 50px;
      position: relative;

      .removeFile {
        margin-left: 10px;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 13px;
          background-color: #999999;
          mask: url(../../../../public/images/icons/cross.svg) no-repeat center;
          mask-size: contain;
        }
      }

      .fileInfo {
        width: calc(100% - 165px);
        height: 100%;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
      }

      input[type='file'] {
        display: none;
      }

      .postAddCommentField {
        transition: border-color $transitionMain;
        width: 100%;
        min-height: 50px;
        max-height: 200px;
        height: 50px;
        padding: $spaceMedium $spaceMain;
        resize: none;
        background-color: $white;
        border: 1px solid $outlineColor;
        border-radius: 25px;
        color: $secondaryTextColor;
        font-family: 'Open Sans', sans-serif;

        &::placeholder {
          color: $thirdTextColor;
        }

        &:hover {
          border-color: darken($outlineColor, $colorMainStep);
        }

        &:focus,
        &:active {
          border-color: $primaryColor;
        }
      }

      .postAddCommentImage {
        transition: opacity $transitionMain;
        position: absolute;
        top: 17px;
        right: $spaceMain;
        line-height: 1;
        cursor: pointer;
        opacity: 0.9;

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 1;
        }
      }
    }

    button {
      border: none;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
    }
  }
}

.actionButton {
  transition: opacity $transitionMain;
  display: inline-block;
  cursor: pointer;
  color: $thirdTextColor;
  font-weight: $fontWeightBold;
  opacity: 0.9;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  .actionButtonIcon {
    margin-right: $spaceSmall;
    vertical-align: middle;
  }
}
