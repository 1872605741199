@import '../mixins.scss';
@import '../variables.scss';


.tlcFooterStyle{
  font-size : 12px;
  height : auto;
  position : relative;
  left : 0;
  bottom : 44px;
  width : 100%;
  background-color : white;
  color : #F26E26;
  text-align : center;
  padding : 8px;
  z-index : 99;
  word-wrap : normal;
  border-top : 1px solid #e2e2e2;
  margin-inline : auto;
}